import { Popup } from '../../../components/Popup'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT } from '../../../constants'
import { Typography } from '../../../components/Typography'
import { ReactComponent as Delete16 } from 'assets/svg/Delete16.svg'
import { useCallback } from 'react'
import { setLoading } from '../../../redux/store/common/slice'
import { useDispatch } from 'react-redux'
import { deleteFacilityUsers } from '../api'
import { TContractFacilityUsersData } from './types'
import { useContractCategoryDetailsPopup } from '../Providers/ContractCategoryDetailsPopupProvider'
import { useContractCategoryDetailsContext } from '../Providers/ContractCategoryDetailsContextProvider'

export type TProps = {
  updateAssignedValues: VoidFunction
  selected: string[]
  selectedUsers: TContractFacilityUsersData[]
}
const DeleteAction = ({
  updateAssignedValues,
  selected,
  selectedUsers
}: TProps) => {
  const { deleteFacilityUsersPopup } = useContractCategoryDetailsPopup()
  const { state, actions } = deleteFacilityUsersPopup
  const { open, close } = actions
  const { visible } = state
  const { state: stateContract } = useContractCategoryDetailsContext()
  const { details } = stateContract
  const dispatch = useDispatch()

  const handleDeleteUsers = useCallback(async () => {
    if (!details.uuid) {
      throw new Error('PRS ID not provided')
    }

    try {
      dispatch(setLoading(true))

      await deleteFacilityUsers(
        details.uuid,
        {
          user_ids: [...selected]
        },

        `User${selected.length > 1 ? 's' : ''} ${
          selected.length > 1 ? 'have' : 'has'
        } been successfully deleted.`
      )

      await updateAssignedValues()

      closeDeleteFacilityUsersPopup()
    } catch (e) {
      console.error(e)
    } finally {
      dispatch(setLoading(false))
    }
  }, [details, dispatch, updateAssignedValues, selected])

  const openDeleteFacilityUsersPopup = () => open()
  const closeDeleteFacilityUsersPopup = () => close()
  return (
    <>
      {selected.length > 0 && (
        <Button
          type={BUTTON_TYPES.PRIMARY}
          onClick={openDeleteFacilityUsersPopup}
          icon={<Delete16 />}
          upperCase
          danger
        >
          {BTN_TXT.DELETE}
        </Button>
      )}
      <Popup
        visible={visible}
        onCancel={closeDeleteFacilityUsersPopup}
        title={`Delete ${
          selected.length === 1
            ? selectedUsers[0].first_name + ' ' + selectedUsers[0].last_name
            : `users`
        }?`}
        width={442}
        footer={
          <>
            <Button
              type={BUTTON_TYPES.DEFAULT}
              onClick={closeDeleteFacilityUsersPopup}
              upperCase
            >
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              onClick={handleDeleteUsers}
              upperCase
              danger
            >
              {BTN_TXT.DELETE}
            </Button>
          </>
        }
      >
        <Typography.Body1>
          {selected.length === 1
            ? `${
                selectedUsers[0].first_name + ' ' + selectedUsers[0].last_name
              }`
            : `${selected.length} users`}{' '}
          will be removed from the Contract Category. Do you really want to
          delete{' '}
          {selected.length === 1
            ? `${
                selectedUsers[0].first_name + ' ' + selectedUsers[0].last_name
              }`
            : `${selected.length} users`}{' '}
          from the Contract Category?
        </Typography.Body1>
      </Popup>
    </>
  )
}

export default DeleteAction
