import { Ability, MongoQuery } from '@casl/ability'
import { TABS_LABELS } from 'constants/common'

export type Roles = ValueOfObject<typeof ROLES>
export type Actions = ValueOfObject<typeof ACTIONS>
export type Subjects = ValueOfObject<typeof SUBJECTS>
export type UI = ValueOfObject<typeof UI>

export type Conditions =
  | Record<PropertyKey, number | string | MongoQuery>
  | MongoQuery<Record<PropertyKey, number | string | MongoQuery>>

export type PossibleAbilities = [Actions, SubjectsUnion]

export type AppAbility = Ability<PossibleAbilities, Conditions>

export type SubjectsUnion = Subjects | UI

export const ROLES = {
  SUPER_USER: 'super_user',
  COMMUNITY_PRESIDENT: 'community_president',
  VICE_PRESIDENT: 'community_vice_president',
  CONTRACT_STEWARD: 'community_contract_steward',
  LEGAL: 'community_legal',
  ANALYST: 'community_analyst',
  HOSPITAL_ADMIN: 'community_hospital_admin',
  STAKEHOLDER: 'community_stakeholder',
  INFLUENCER: 'community_influencer',
  VENDOR_CONTRACT_ADMIN: 'vendor_contract_admin',
  VENDOR_CONTRACT_STEWARD: 'vendor_contract_steward',
  VENDOR_ANALYST: 'vendor_analyst',
  VENDOR_LEGAL: 'vendor_legal',
  SALES_REPRESENTATIVE: 'vendor_sales_rep',
  VENDOR_FINANCE_OFFICER: 'vendor_finance_officer',
  COMMUNITY_RCA: 'community_rca',
  COMMONS_LEGAL: 'commons_legal',
  COMMONS_ANALYST: 'commons_analyst',
  COMMONS_STAKEHOLDER: 'commons_stakeholder',
  COMMONS_VICE_PRESIDENT: 'commons_vice_president',
  COMMONS_CLINICAL_COORDINATOR: 'commons_clinical_coordinator',
  COMMONS_CONTRACT_STEWARD: 'commons_contract_steward'
} as const

export const ACTIONS = {
  CRUD: 'CRUD',
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete',
  VIEW: 'view',
  CLOSE: 'close',
  VOTE: 'vote',
  ACCEPT: 'accept',
  CREATE_ANSWER: 'create answer',
  UPLOAD: 'upload',
  SEND: 'send',
  CANCEL: 'cancel',
  EDIT: 'edit',
  AWARD: 'award',
  SUBMIT: 'submit',
  EXTEND: 'extend',
  ARCHIVE: 'archive',
  DISABLED: 'disabled',
  ADD: 'add',
  RESEND: 'RESEND',
  DOWNLOAD: 'download',
  MANAGE: 'MANAGE'
}

export const SUBJECTS = {
  DASHBOARD: 'Dashboard',
  COMPLIANCE: 'Compliance',
  MARKET_INSIGHTS: 'Market Insights',
  NOTIFICATIONS: 'Notifications',
  CONTRACTS: 'Contracts',
  BULK_CONTRACTS: 'Bulk Contracts',
  CONTRACT_CATEGORY: 'Contract Category',
  BIDDING: 'Bidding',
  VENDORS: 'Vendors',
  CHAT: 'Chat',
  AVATAR: 'Avatar',
  SETTINGS: 'Settings',
  LOG_OUT: 'Log Out',
  PROFILE: 'Profile',
  FINANCIALS: 'Financials',
  PRODUCT_REQUESTS: 'Product Requests',
  HEALTH_SYSTEMS: 'Health Systems',
  HEALTH_SYSTEMS_TAB: 'Health Systems',
  HEALTH_SYSTEM_USERS: 'Health System users',
  VENDOR: 'Vendor',
  VENDOR_USERS: 'Vendor users',
  FORM_HOSPITAL_LIST: 'form hospital list',
  FORM_COMMUNITIES_WITH_HS_LIST: 'form communities with hs list',
  FORM_CHAPTER_INPUT: 'form chapter input',
  FORM_SAVINGS_GOAL: 'form savings goal',
  FORM_HEALTH_SYSTEM_INPUT: 'form health system input',
  FORM_CONTARCT_CATEGORIES_INPUT: 'form contract categories input',
  FORM_HOSPITAL_INPUT: 'form hospital input',
  FORM_DEPARTMENTS_INPUT: 'form departments input',
  CONTRACT_STEWARDS_LIST: 'contract stewards list',
  VENDOR_CONTRACT_ADMIN: 'vendor contract admin',
  HEALTH_SYSTEM_TAB: 'Health System',
  HEALTH_SYSTEM: 'Health System',
  HOSPITAL: 'Hospital',
  HOSPITAL_USERS: 'Hospital users',
  RFP: 'RFP',
  DEPARTMENTS: 'DEPARTMENTS',
  RFP_DOCUMENTS: 'RFP_DOCUMENTS',
  CONTRACT_DOCUMENTS: 'CONTRACT_DOCUMENTS',
  SURVEY: 'SURVEY',
  VOTING: 'VOTING',
  VOTING_RESULTS: 'VOTING_RESULTS',
  RESPONSIBLE_USER: 'RESPONSIBLE_USER',
  CONTRACT_DETAILS: 'CONTRACT_DETAILS',
  START_SIGNING_PROCESS: 'START_SIGNING_PROCESS',
  CONTRACT_SIGNED: 'CONTRACT_SIGNED',
  RFP_RESULTS: 'RFP_RESULTS',
  FORM_ITEM: 'FORM_ITEM',
  USER: 'USER',
  PURCHASE_ORDERS: 'Purchase Orders',
  INVOICE_DATA: 'Invoice data',
  CONTRACT_STEWARDS_LIST_BUTTON: 'CONTRACT_STEWARDS_LIST_BUTTON',
  VENDOR_SELECT: 'VENDOR_SELECT',
  COMMUNITY: 'Community',
  COMMUNITY_USERS: 'Community users',
  FORM_COMMUNITY_INPUT: 'FORM_COMMUNITY_INPUT',
  BANK_ACCOUNT: 'BANK_ACCOUNT',
  ACCOUNT_DETAILS: 'ACCOUNT_DETAILS',
  CONTACT_PERSON: 'CONTACT_PERSON',
  COMMONS_USERS: 'Commons users',
  CLINICAL_REVIEW: 'Clinical Review',
  ACTUAL_SAVINGS_MENU: 'ACTUAL_SAVINGS_MENU',
  CALENDAR: 'Calendar',
  CALENDAR_EVENT: 'Calendar Event',
  CALENDAR_EVENT_RESPONSE: 'Calendar Event Response',
  CONTRACT_PIPELINE: 'Contract pipeline',
  CONTRACT_PIPELINE_LED_BY: 'Contract pipeline Led by',
  TO_COMMONS: 'To commons',
  BID: 'BID',
  ASSIGN_RFP_BANNER: 'ASSIGN_RFP_BANNER',
  COMMONS_RESPONSIBLE_USER: 'COMMONS_RESPONSIBLE_USER',
  VENDORS_TAB: 'VENDORS_TAB',
  CHECK_INS: 'Check-Ins',
  MY_COMMUNITY: 'My Community',
  ADDITIONAL_INFO_PROFILE: 'ADDITIONAL_INFO_PROFILE',
  QR_CODE: 'QR_CODE',
  COMMONS_RESPONSIBLE_USERS: 'COMMONS_RESPONSIBLE_USERS',
  APPROVE_PENDING_USERS: 'APPROVE_PENDING_USERS',
  HIDE_PHONE_NUMBER: 'HIDE_PHONE_NUMBER',
  OWNER: 'OWNER',
  RFP_VENDOR_ACTIONS: 'RFP_VENDOR_ACTIONS',
  USER_CONTRACT_CATEGORIES: 'USER_CONTRACT_CATEGORIES',
  GPO: 'GPO',
  FACILITY_USERS: 'FACILITY_USERS'
}

export const UI = {
  ...TABS_LABELS
} as const
