import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { IconFullName } from '../../../HealthSystemDetails/IconFullName'
import { HealthSystems } from '../../HealthSystems/HealthSystems'
import { Typography } from '../../../../components/Typography'
import { setLoading } from '../../../../redux/store/common/slice'
import { getCommunityDetails } from './api'
import { formatPhoneNumber } from '../../../../helper/common'
import { onStartChat } from '../../utils'
import { getUser } from '../../../../redux/store/user/getters'
import { ROLES_NAMES } from '../../../../constants'
import { ROLES } from '../../../Permission'
import { CommunityData, CommunityDetailsProps } from './types'
import './styles.scss'

export const CommunityDetails: FC<CommunityDetailsProps> = ({
  communityId,
  healthSystems
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [communityData, setCommunityData] = useState<CommunityData>()
  const user = useSelector(getUser)
  const dispatch = useDispatch()

  const hideChat =
    user.role === ROLES.COMMUNITY_PRESIDENT &&
    user.uuid === communityData?.contact_person.uuid

  useEffect(() => {
    getCommunityData()
  }, [])

  const getCommunityData = async () => {
    try {
      dispatch(setLoading(true))
      const response = await getCommunityDetails(communityId)
      setCommunityData(response.data)
    } finally {
      dispatch(setLoading(false))
    }
  }

  const onChangeCollapse = () => setIsCollapsed((prev) => !prev)

  return (
    <div className="community-details">
      <div className="community-details__content">
        {!isCollapsed && (
          <IconFullName
            firstName={communityData?.contact_person?.first_name}
            lastName={communityData?.contact_person?.last_name}
            roleName={
              communityData?.contact_person?.role
                ? ROLES_NAMES[communityData?.contact_person?.role]
                : ''
            }
            phone={formatPhoneNumber(
              communityData?.contact_person?.phone_number || ''
            )}
            email={communityData?.contact_person?.email}
            src={communityData?.contact_person?.avatar_logo?.file_preview}
            uuid={communityData?.contact_person?.uuid}
            onStartChat={onStartChat}
            hideChat={hideChat}
          />
        )}
        <Button
          className="mt-16"
          type={BUTTON_TYPES.LINK}
          onClick={onChangeCollapse}
          inline
        >
          {`${isCollapsed ? 'Show more' : 'Hide'} information`}
        </Button>
        <Typography.Headline6 className="mt-32">
          Health systems
        </Typography.Headline6>
      </div>
      <HealthSystems
        healthSystems={healthSystems}
        idMyHealthSystem={user.health_system}
      />
    </div>
  )
}
