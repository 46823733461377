import Fetcher from '../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../constants'
import {
  ChangeCommunityLevelRequest,
  UserContractCategoriesResponse
} from './types'

const fetcher = new Fetcher({})

export const getUserContractCategories = async (uuid: string) =>
  fetcher.request<unknown, UserContractCategoriesResponse>({
    url: `${API_DICTIONARY.USERS}${uuid}/${API_DICTIONARY.CONTRACT_CATEGORIES}`,
    method: HTTP_METHODS.GET
  })

export const changeCommunityLevel: ChangeCommunityLevelRequest = async (
  userId,
  ccId,
  data
) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.USERS}${userId}/${API_DICTIONARY.CONTRACT_CATEGORIES}${ccId}/${API_DICTIONARY.UPDATE_LEVEL}`,
    method: HTTP_METHODS.PATCH,
    isNotificationError: true,
    data
  })
