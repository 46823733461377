import { useState } from 'react'
import { Popover } from 'antd'
import cn from 'classnames'
import { ReactComponent as Close24 } from 'assets/svg/Close24.svg'
import './style.scss'
import { TResponsiblePopover, TResponsiblePopoverContent } from './types'
import { Link } from '../../components/Link'
import { routes } from '../../router'
import { CellWithSubtitle } from '../../components/CellWithSubtitle/CellWithSubtitle'
import { LeadByIndicator } from '../../components/LeadByIndicator'
import { useContractPageContext } from '../../pages/Contracts/Providers/ContractPageContextProvider'

const CategoriesContent = ({ led_by, name }: TResponsiblePopoverContent) => {
  const { state } = useContractPageContext()

  return (
    <div className="column">
      {led_by.map((ledByOption) => {
        return (
          <CellWithSubtitle
            className="contract-link full-width"
            title={
              <div className="row full-width">
                {state.isCanSeeCategoryDetails && ledByOption.uuid ? (
                  <Link
                    to={`${routes.contracts}${routes.contractCategories}/${ledByOption.uuid}`}
                    className="full-width gap-8 align-center"
                  >
                    <span className="flex-item">{name}&nbsp;led by</span>
                    <LeadByIndicator value={ledByOption.led_by} />
                  </Link>
                ) : (
                  <div className="full-width gap-8 align-center p-8 row">
                    {name} led by
                    <LeadByIndicator value={ledByOption.led_by} />
                  </div>
                )}
              </div>
            }
          />
        )
      })}
    </div>
  )
}

const CategoriesPopover = ({ led_by, name, uuid }: TResponsiblePopover) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleSetIsOpen = (open) => setIsOpen(open)

  const { state } = useContractPageContext()

  const redirectLink = led_by.length
    ? state.isCanSeeCategoryDetails && led_by[0].uuid
      ? `${routes.contracts}${routes.contractCategories}/${led_by[0].uuid}`
      : ''
    : state.isCanSetUpEditCategory
    ? `${routes.contracts}${routes.contractCategories}/new?contract_category=${uuid}`
    : ''

  return led_by.length > 1 ? (
    <Popover
      className={cn('categories-responsible-cell', '')}
      trigger="click"
      overlayClassName={'categories-responsible-popover'}
      placement="topLeft"
      content={<CategoriesContent led_by={led_by} name={name} />}
      overlayInnerStyle={{
        maxHeight: 'calc(100vh - 100px)',
        overflow: 'auto'
      }}
      align={{
        points: ['tl', 'tl']
      }}
      visible={isOpen}
      onVisibleChange={handleSetIsOpen}
      title={
        <div className="description-cell__content-header">
          <div
            className="description-cell__close"
            onClick={() => setIsOpen(false)}
          >
            <Close24 />
          </div>
        </div>
      }
    >
      <div className="description-cell__text" onClick={() => setIsOpen(true)}>
        {name}
      </div>
    </Popover>
  ) : (
    <CellWithSubtitle
      className="contract-link"
      title={redirectLink ? <Link to={redirectLink}>{name}</Link> : <>{name}</>}
    />
  )
}

export default CategoriesPopover
