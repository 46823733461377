import { LED_BY_OPTIONS } from '../../../components/LeadByIndicator/constants'

export const CC_DETAILS_INITIAL = {
  uuid: '',
  contract_category_name: '',
  expected_spend: '',
  expected_savings: '',
  compliance_requirement: '',
  organization: '',
  portfolio: {
    name: '',
    uuid: ''
  },
  led_by: LED_BY_OPTIONS.health_system,
  community_responsibles: [],
  commons_responsibles: []
}
