import { IRFPSurveyCreateRequestPayload } from '../types'

export const RFP_SURVEY_TABS = [
  {
    key: '0',
    label: 'Vendors selection'
  },
  {
    key: '1',
    label: 'Survey preview'
  }
]

export const RFP_DEFAULT_VENDOR_LINE: Partial<IRFPSurveyCreateRequestPayload> =
  {
    vendor: undefined,
    category: []
  }

export const idValueSettings = {
  labelKey: 'value',
  valueKey: 'id'
}

export const SURVEY_VENDORS_RATING_RANGE = 3
