import { Input } from '../../../components/Input'
import { BTN_TXT, PLACEHOLDERS } from '../../../constants'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import { ReactComponent as Edit16 } from 'assets/svg/Edit16.svg'
import { ReactComponent as EmptyBox } from 'assets/svg/EmptyBox.svg'
import { Banner } from '../../../components/Banner/Banner'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { usePendingClassificationTableContext } from '../Providers/PendingClassificationTableProvider'
import { Table } from '../../../components/Table'
import { TUncategorizedInvoiceWithKey } from './types'
import { columns } from './constants'
import { Typography } from '../../../components/Typography'
import { usePendingClassificationPopupContext } from '../Providers/PendingClassificationPopupProvider'
import { usePendingClassificationEditContext } from '../Providers/PendingClassificationEditProvider'
import Popup from '../../../components/Popup/Popup'
import { useShowPopup } from '../../../hooks/useShowPopup'

export const PendingClassification = () => {
  const { state, actions } = usePendingClassificationTableContext()
  const { state: editState, actions: editActions } =
    usePendingClassificationEditContext()
  const {
    similarItemsSidebarDrawer,
    bulkClassificationPopup,
    confirmSavePopup
  } = usePendingClassificationPopupContext()
  const [showPopup, confirmNavigation, cancelNavigation] = useShowPopup(
    editState.hasPendingChanges
  )
  return (
    <div className="table-wrapper">
      {state.totalNumber > 0 && (
        <>
          <Banner.ErrorWithTitle
            text="Please correct the value by clicking on the highlighted cells."
            title={`${state.totalNumber} lines need to be classified`}
            className="mb-16"
          />
          <div className="table-wrapper__header">
            <Input
              className="allow-clear"
              propsInput={{
                allowClear: { clearIcon: <Close16 /> },
                placeholder: PLACEHOLDERS.SEARCH,
                prefix: <Search16 />,
                value: state.search,
                onChange: (e) => actions.setSearch(e.target.value)
              }}
            />
            <div className="row gap-8">
              <Button
                type={BUTTON_TYPES.PRIMARY}
                icon={<Edit16 />}
                onClick={() => {
                  bulkClassificationPopup.actions.open({
                    keys: editState.selectedRows,
                    contractCategories: editState.contractCategories
                  })
                }}
                upperCase
                hidden={editState.isEditHidden}
              >
                {BTN_TXT.EDIT}
              </Button>
              <Button
                type={BUTTON_TYPES.PRIMARY}
                onClick={() => confirmSavePopup.actions.open()}
                upperCase
                hidden={editState.saveHidden}
              >
                {BTN_TXT.SAVE}
              </Button>
            </div>
          </div>
          <Table<TUncategorizedInvoiceWithKey>
            dataSource={state.tableData ?? []}
            columns={columns({
              onViewDetailsPressed: (uuid) => {
                similarItemsSidebarDrawer.actions.open({ uuid })
              },
              ccOptions: editState.contractCategories,
              setChangedRows: editActions.setChangedRows,
              changedRows: editState.changedRows
            })}
            hasSearchOrFilters={true}
            pageSize={state.pageInfo.pageSize}
            onChange={actions.handleTableChange}
            onChangePage={actions.onPageSizeChange}
            scroll={{ scrollToFirstRowOnChange: true, x: 'max-content' }}
            pagination={{
              pageSize: state.pageInfo.pageSize,
              current: state.pageInfo.pageNumber,
              total: state.totalNumber
            }}
            rowSelection={{
              selectedRowKeys: editState.selectedRows,
              onChange: (keys) => editActions.setSelectedRows(keys),
              preserveSelectedRowKeys: true
            }}
          />
        </>
      )}
      {!state.totalNumber && !state.firstLoad && (
        <div className="flex column full-width align-center mt-140">
          <EmptyBox />
          <Typography.Headline6 className="mt-40">
            All caught up! No items pending classification.
          </Typography.Headline6>
        </div>
      )}
      <Popup.Discard
        visible={showPopup}
        onCancel={cancelNavigation}
        onSubmit={confirmNavigation}
      />
    </div>
  )
}
