import { useRFPCreateSurveyContext } from 'features/RFP/RFPSurvey/CreateSurvey/Providers/RFPCreateSurveyContextProvider'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { RFP_STATUSES } from 'features/RFP/constants'
import SingleVendorCCSelection from './SingleVendorCCSelection'
import { Button, BUTTON_TYPES } from '../../../../../components/Button'
import { BTN_TXT } from '../../../../../constants'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { useRFPSurveyContext } from '../../../RFPDetails/Providers/RFPSurveyContextProvider'

const VendorsSelection = () => {
  const rfpDetailsContext = useRFPDetailsContext()
  const rfpSurveyContext = useRFPSurveyContext()
  const createSurveyContext = useRFPCreateSurveyContext()

  const { data } = rfpDetailsContext.state
  const { vendors } = rfpSurveyContext.state
  const { localSelectedPickersValues } = createSurveyContext.state
  const {
    addVendorCCLine,
    removeVendorCCLine,
    getSpecificVendorPickerOptions,
    getSpecificCCPickerOptions,
    updateVendorValue,
    updateCCValue
  } = createSurveyContext.actions

  const isDisabled = data.status !== RFP_STATUSES.PRICE_FILES_ANALYSIS

  return (
    <div>
      <div>
        {localSelectedPickersValues.map(
          (singleVendorPickerValues, lineIndex) => (
            <SingleVendorCCSelection
              vendorOptions={getSpecificVendorPickerOptions(
                singleVendorPickerValues.vendor
              )}
              onChangeVendor={(vendorValue) =>
                updateVendorValue(lineIndex, vendorValue)
              }
              vendorValue={singleVendorPickerValues.vendor}
              ccOptions={getSpecificCCPickerOptions(
                singleVendorPickerValues.vendor
              )}
              onChangeCC={(ccList) =>
                singleVendorPickerValues.vendor &&
                updateCCValue(
                  lineIndex,
                  singleVendorPickerValues.vendor,
                  ccList
                )
              }
              ccValues={singleVendorPickerValues.category}
              disabled={isDisabled}
              deleteVisible={
                !isDisabled && localSelectedPickersValues.length > 1
              }
              deletePress={() => removeVendorCCLine(lineIndex)}
            />
          )
        )}
      </div>
      {!isDisabled && (
        <Button
          icon={<Plus16 />}
          type={BUTTON_TYPES.GHOST}
          onClick={addVendorCCLine}
          disabled={vendors.length <= localSelectedPickersValues.length}
          upperCase
        >
          {BTN_TXT.ADD_VENDOR}
        </Button>
      )}
    </div>
  )
}

export default VendorsSelection
