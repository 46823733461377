import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo
} from 'react'
import { useDispatch } from 'react-redux'

import { setLoading } from 'redux/store/common/slice'
import { TResponsibleUser } from 'features/RFP/RFPDetails/types'
import { useContractCategoryDetailsContext } from './ContractCategoryDetailsContextProvider'
import { VALIDATION_MESSAGES } from '../../../constants'
import { ROLES } from '../../Permission'
import { updateCCResponsibleUsersRequestAsync } from '../api'
import { useContractCategoryDetailsPopup } from './ContractCategoryDetailsPopupProvider'

type ContextProps = {
  actions: {
    deleteResponsibleUserAsync: (
      responsibleUserUuid: TResponsibleUser['uuid']
    ) => Promise<void>
    addResponsibleUserAsync: (
      responsibleUsers: Array<TResponsibleUser['uuid']>
    ) => Promise<void>
  }
}

const ContractCategoryResponsibleContext = createContext<ContextProps>({
  actions: null!
})

const ContractCategoryResponsibleContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const dispatch = useDispatch()

  const ccDetailsContext = useContractCategoryDetailsContext()
  const { deleteResponsibleUserPopup, addResponsibleUserPopup } =
    useContractCategoryDetailsPopup()

  const { details, isCommons } = ccDetailsContext.state
  const responsibleUsers = useMemo(() => {
    return (
      (isCommons
        ? details.commons_responsibles
        : details.community_responsibles) ?? []
    )
  }, [details, isCommons])
  const { getCCDetailsAsync } = ccDetailsContext.actions

  const deleteResponsibleUserAsync = useCallback(
    async (responsibleUserUuid: TResponsibleUser['uuid']) => {
      if (!details.uuid) {
        throw new Error('Contract ID not provided')
      }
      const responsibles = responsibleUsers
        .filter(
          (u) =>
            u.uuid !== responsibleUserUuid &&
            u.role !== ROLES.VENDOR_CONTRACT_STEWARD
        )
        .map((u) => u.uuid)
      try {
        dispatch(setLoading(true))

        await updateCCResponsibleUsersRequestAsync(
          details.uuid,
          {
            responsibles
          },
          VALIDATION_MESSAGES.TEAM_MEMBER_USER_DELETED
        )

        await getCCDetailsAsync()

        deleteResponsibleUserPopup.actions.close()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [
      deleteResponsibleUserPopup.actions,
      details,
      responsibleUsers,
      dispatch,
      getCCDetailsAsync
    ]
  )

  const addResponsibleUserAsync = useCallback(
    async (responsibles: Array<TResponsibleUser['uuid']>) => {
      if (!details.uuid) {
        throw new Error('Contract ID not provided')
      }

      try {
        dispatch(setLoading(true))

        await updateCCResponsibleUsersRequestAsync(
          details.uuid,
          {
            responsibles
          },
          VALIDATION_MESSAGES.TEAM_MEMBER_USER_ADDED
        )

        await getCCDetailsAsync()

        addResponsibleUserPopup.actions.close()
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [details.uuid, dispatch, getCCDetailsAsync, addResponsibleUserPopup.actions]
  )

  const actions = useMemo(
    () => ({
      deleteResponsibleUserAsync,
      addResponsibleUserAsync
    }),
    [deleteResponsibleUserAsync, addResponsibleUserAsync]
  )

  return (
    <ContractCategoryResponsibleContext.Provider value={{ actions }}>
      {children}
    </ContractCategoryResponsibleContext.Provider>
  )
}

export const useContractCategoryResponsibleContext = () =>
  useContext(ContractCategoryResponsibleContext)

export default ContractCategoryResponsibleContextProvider
