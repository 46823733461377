import { Popup } from '../../../../components/Popup'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { BTN_TXT, LABELS, PLACEHOLDERS } from '../../../../constants'
import { ability, ACTIONS, SUBJECTS } from '../../../Permission'
import { FieldWithLabel } from '../../../../components/FieldWithLabel/FieldWithLabel'
import { LeadByIndicator } from '../../../../components/LeadByIndicator'
import { LED_BY_VALUE } from '../../../../components/LeadByIndicator/constants'
import Typography from '../../../../components/Typography/Typography'
import { ReactComponent as Plus16 } from 'assets/svg/Plus16.svg'
import { ReactComponent as EditIcon } from 'assets/svg/EditBlue16.svg'
import { useCallback, useMemo, useState } from 'react'
import { THandleGpoProps } from './types'
import { Input } from '../../../../components/Input'
import { setLoading } from '../../../../redux/store/common/slice'
import { useDispatch, useSelector } from 'react-redux'
import { updateNationalGpoName } from './api'
import { notification } from '../../../../components/Notification'
import { useParams } from 'react-router-dom'
import { getUser } from '../../../../redux/store/user/getters'

const HandleGpo = ({ national_gpo, getDetails }: THandleGpoProps) => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const { id: hsId } = useParams()

  const [gpo, setGpo] = useState<string>('')
  const [showPopup, setShowPopup] = useState<boolean>(false)

  const onCloseModal = () => setShowPopup(false)
  const onOpenModal = () => {
    setGpo(national_gpo?.name || '')
    setShowPopup(true)
  }
  const canManageGpo = useMemo(
    () =>
      ability.can(ACTIONS.MANAGE, SUBJECTS.GPO) && hsId === user.health_system,
    [hsId, user.health_system]
  )
  const canSeeGpo = useMemo(
    () =>
      ability.can(ACTIONS.VIEW, SUBJECTS.GPO) && hsId === user.health_system,
    [hsId, user.health_system]
  )
  const onSubmitGpo = useCallback(
    async (value) => {
      dispatch(setLoading(true))
      if (!hsId) return
      try {
        const resp = await updateNationalGpoName(hsId, {
          name: value
        })
        if (!!resp?.data?.name) {
          getDetails(hsId)
          notification.success({
            message: `GPO has been updated`
          })
        }
      } catch (err) {
        console.error(err)
      } finally {
        onCloseModal()
        setGpo('')
        dispatch(setLoading(false))
      }
    },
    [hsId]
  )
  return (
    <>
      {canSeeGpo && !!national_gpo?.name ? (
        <div className="row space-between national-gpo-wrapper">
          <FieldWithLabel title={LABELS.GPO} className="gpo-field">
            <div className="row gap-8">
              <LeadByIndicator value={LED_BY_VALUE.NATIONAL_GPO} />
              <Typography.Body1>{national_gpo?.name}</Typography.Body1>
            </div>
          </FieldWithLabel>
          {canManageGpo && (
            <div className="gpo-edit-icon" onClick={onOpenModal}>
              <EditIcon />
            </div>
          )}
        </div>
      ) : canManageGpo ? (
        <FieldWithLabel title={LABELS.GPO} className="gpo-field">
          <Button
            className="contract-categories__add-btn"
            onClick={onOpenModal}
            type={BUTTON_TYPES.GHOST}
            icon={<Plus16 />}
            upperCase
            medium
          >
            {BTN_TXT.ADD_GPO}
          </Button>
        </FieldWithLabel>
      ) : null}
      {showPopup && (
        <Popup
          title={`${!national_gpo?.name ? 'Add' : 'Edit'} GPO`}
          className="contract-categories__categories-popup"
          visible={showPopup}
          onCancel={onCloseModal}
          footer={
            <>
              <Button
                onClick={onCloseModal}
                type={BUTTON_TYPES.DEFAULT}
                upperCase
              >
                {BTN_TXT.CANCEL}
              </Button>
              <Button
                type={BUTTON_TYPES.PRIMARY}
                disabled={!gpo.length}
                onClick={() => onSubmitGpo(gpo)}
                upperCase
              >
                {BTN_TXT.SAVE}
              </Button>
            </>
          }
        >
          <Input
            propsItem={{
              label: LABELS.GPO_NAME
            }}
            propsInput={{
              value: gpo,
              onChange: (e) => {
                setGpo(e.target.value)
              },
              placeholder: PLACEHOLDERS.TYPE_IN_GPO_NAME
            }}
          />
        </Popup>
      )}
    </>
  )
}

export default HandleGpo
