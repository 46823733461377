import { useContractCategoryDetailsContext } from '../../Providers/ContractCategoryDetailsContextProvider'
import { FieldWithLabel } from '../../../../components/FieldWithLabel/FieldWithLabel'
import { LABELS } from '../../../../constants'
import { LeadByIndicator } from '../../../../components/LeadByIndicator'
import { Typography } from '../../../../components/Typography'
import {
  LED_BY_LABEL,
  LED_BY_VALUE
} from '../../../../components/LeadByIndicator/constants'

export const AdditionalInfo = () => {
  const { state } = useContractCategoryDetailsContext()
  const { details } = state

  return (
    <>
      <div className="contract-category-details-general__details__grid column full-width">
        <FieldWithLabel title={LABELS.LED_BY} className="led-by-field">
          <div className="row gap-8">
            <LeadByIndicator value={details.led_by} />
            <Typography.Body1>{LED_BY_LABEL[details.led_by]}</Typography.Body1>
          </div>
        </FieldWithLabel>
        {[LED_BY_VALUE.NATIONAL_GPO, LED_BY_VALUE.HEALTH_SYSTEM].includes(
          details.led_by
        ) && (
          <FieldWithLabel title={LABELS.HEALTH_SYSTEM} className="mt-16">
            <Typography.Body1>{details.organization}</Typography.Body1>
          </FieldWithLabel>
        )}
        {details.led_by.includes('community') && (
          <FieldWithLabel title={LABELS.COMMUNITY} className="mt-16">
            <Typography.Body1>{details.organization}</Typography.Body1>
          </FieldWithLabel>
        )}
        <FieldWithLabel title={LABELS.PORTFOLIO} className="mt-16">
          <Typography.Body1>{details.portfolio?.name}</Typography.Body1>
        </FieldWithLabel>
      </div>
    </>
  )
}
