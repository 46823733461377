import { FieldWithLabel } from '../../../../components/FieldWithLabel/FieldWithLabel'
import { LABELS } from '../../../../constants'
import { Typography } from '../../../../components/Typography'
import { useContractCategoryDetailsContext } from '../../Providers/ContractCategoryDetailsContextProvider'
import { currencyFormatter } from '../../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'

export const ViewContractCategory = () => {
  const { state } = useContractCategoryDetailsContext()
  const { details } = state

  return (
    <>
      <div className="contract-category-details-general__details__grid column">
        <div className="row full-width gap-24">
          <FieldWithLabel
            title={LABELS.EXPECTED_SPEND}
            className="cc-details-item-half"
          >
            <Typography.Body1>
              {!!details.expected_spend
                ? currencyFormatter(details.expected_spend)
                : '-'}
            </Typography.Body1>
          </FieldWithLabel>
          <FieldWithLabel
            title={LABELS.EXPECTED_SAVINGS}
            className="cc-details-item-half"
          >
            <Typography.Body1>
              {!!details.expected_savings
                ? currencyFormatter(details.expected_savings)
                : '-'}
            </Typography.Body1>
          </FieldWithLabel>
        </div>
        <div className="full-width mt-24">
          <FieldWithLabel
            title={LABELS.COMPLIANCE_REQUIREMENT}
            className="cc-details-item"
          >
            <Typography.Body1>
              {details.compliance_requirement || '-'}
            </Typography.Body1>
          </FieldWithLabel>
        </div>
      </div>
    </>
  )
}
