import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'antd'
import { setLoading } from '../../../redux/store/common/slice'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  TAddEventFormErrorType,
  TAddEventFormType
} from '../AddEventPopup/types'
import {
  createCalendarEvent,
  editCalendarEvent,
  getOptionsTypeList
} from '../../../pages/Calendar/api'
import {
  RespondToTheMeeting,
  TOptionTypeEvent
} from '../../../pages/Calendar/types'
import { TDropdownItem } from '../../../components/Dropdown/types'
import { disabledDateBefore } from '../../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { Moment } from 'moment'
import { AxiosResponse } from 'axios'
import { useCalendarPopup } from '../../../pages/Calendar/Provider/CalendarPopupProvider'
import { TOption } from '../../../components/Select/types'
import { useCalendarEventContext } from '../../../pages/Calendar/CalendarEventsProvider'
import { parseDataForEvent } from '../AddEventPopup/helper'
import { getUser } from '../../../redux/store/user/getters'

const useAddEventModal = () => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const { addEventPopup, notifyUsersPopup } = useCalendarPopup()
  const { appendToCalendarEvents, amendToCalendarEvents, getEventDetails } =
    useCalendarEventContext().actions
  const { calendarEventDetails } = useCalendarEventContext().state
  const [form] = Form.useForm<TAddEventFormType>()
  const [editFormData, setEditFormData] = useState<TAddEventFormType | null>(
    null
  )
  const [errors, setErrors] = useState<TAddEventFormErrorType>({})
  const [typesSelectOptions, setTypesSelectOptions] = useState<TOption[]>([])
  const [typesOptions, setTypesOptions] = useState<TDropdownItem[]>([])

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false)

  const onSubmit = useCallback(
    async (values: TAddEventFormType) => {
      const data = parseDataForEvent(
        values,
        typesSelectOptions,
        user.timezone.offset
      )
      if (!!calendarEventDetails?.uuid) {
        if (
          !(
            !!calendarEventDetails?.participants?.length ||
            !!values.participants.length
          )
        ) {
          editEvent(false, values)
          return
        }
        if (
          calendarEventDetails?.event_type?.id !== values?.event_type ||
          calendarEventDetails?.location !== data?.location ||
          calendarEventDetails?.start_date !== data?.start_date ||
          calendarEventDetails?.end_time.substring(0, 5) !== data?.end_time ||
          calendarEventDetails?.end_date !== data?.end_date ||
          calendarEventDetails?.start_time.substring(0, 5) !== data?.start_time
        ) {
          addEventPopup.actions.close()
          notifyUsersPopup.actions.open()
          setEditFormData(values)
        } else {
          editEvent(false, values)
          addEventPopup.actions.close()
        }
        return
      }
      dispatch(setLoading(true))
      setIsSaveButtonDisabled(true)
      try {
        const event = await createCalendarEvent(data)
        appendToCalendarEvents([
          {
            ...event.data,
            respond: RespondToTheMeeting.ACCEPT
          }
        ])
        form.resetFields()
        addEventPopup.actions.close()
      } catch (error) {
        const typedResponse = error as AxiosResponse
        setErrors(typedResponse.data)
      } finally {
        dispatch(setLoading(false))
        setIsSaveButtonDisabled(false)
      }
    },
    [
      dispatch,
      typesSelectOptions,
      appendToCalendarEvents,
      calendarEventDetails,
      user,
      setIsSaveButtonDisabled,
      isSaveButtonDisabled
    ]
  )

  const editEvent = useCallback(
    async (isNotified: boolean, values?: TAddEventFormType | null) => {
      const editedData = !!values ? values : editFormData
      if (!editedData || !calendarEventDetails?.uuid) return
      dispatch(setLoading(true))
      setIsSaveButtonDisabled(true)
      const data = parseDataForEvent(
        editedData,
        typesSelectOptions,
        user.timezone.offset
      )

      try {
        const event = await editCalendarEvent(calendarEventDetails?.uuid, {
          ...data,
          notify_participants: isNotified
        })
        amendToCalendarEvents({
          ...event.data,
          respond: RespondToTheMeeting.ACCEPT
        })
        form.resetFields()
        getEventDetails(null)
        addEventPopup.actions.close()
      } catch (error) {
        const typedResponse = error as AxiosResponse
        setErrors(typedResponse.data)
        addEventPopup.actions.open()
      } finally {
        dispatch(setLoading(false))
        setIsSaveButtonDisabled(false)
      }
    },
    [
      dispatch,
      typesSelectOptions,
      calendarEventDetails,
      amendToCalendarEvents,
      editFormData,
      user,
      setIsSaveButtonDisabled,
      isSaveButtonDisabled
    ]
  )

  const startDate = Form.useWatch('start_date', form)

  const disabledDateSameOrBeforeStartDate = useMemo(
    () => disabledDateBefore(startDate as Moment),
    [startDate]
  )

  useEffect(() => {
    if (
      calendarEventDetails?.event_type?.id === 'other' &&
      !!typesOptions?.length
    ) {
      setTypesSelectOptions(
        typesOptions.map((tso) => {
          if (tso.value === 'other')
            return {
              value: calendarEventDetails.event_type.id,
              label: calendarEventDetails.event_type.value
            }
          else return tso
        })
      )
    } else {
      setTypesSelectOptions(typesOptions)
    }
  }, [typesOptions, calendarEventDetails])

  const getTypeOptions = useCallback(async () => {
    const results = await getOptionsTypeList()
    if (!!results?.data?.results) {
      setTypesOptions(
        results?.data?.results.map((type: TOptionTypeEvent) => ({
          label: type.value,
          value: type.id
        }))
      )
    }
  }, [])

  useEffect(() => {
    getTypeOptions()
  }, [])

  return {
    form,
    onSubmit,
    errors,
    setErrors,
    typesOptions,
    disabledDateSameOrBeforeStartDate,
    typesSelectOptions,
    setTypesSelectOptions,
    editEvent,
    setEditFormData,
    isSaveButtonDisabled
  }
}
export default useAddEventModal
