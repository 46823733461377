import { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Status from '../../../../components/Status/Status'
import Typography from '../../../../components/Typography/Typography'
import { Container } from '../../../../components/Container/Container'
import { FieldWithLabel } from '../../../../components/FieldWithLabel/FieldWithLabel'
import { Button, BUTTON_TYPES } from '../../../../components/Button'
import { HealthSystemUsers } from '../HealthSystemUsers/HealthSystemUsers'
import { IconFullName } from '../../../HealthSystemDetails/IconFullName'
import { setLoading } from '../../../../redux/store/common/slice'
import { getHealthSystemDetails } from './api'
import {
  formatAddress,
  formatPhoneNumber,
  fullFormatPhoneNumber
} from '../../../../helper/common'
import { onStartChat } from '../../utils'
import { ROLES_NAMES } from '../../../../constants'
import { HEALTH_SYSTEM_STATUSES_CLASSNAME } from '../../../../components/Status'
import { BREADCRUMBS, initialHealthSystemDetails } from './constants'
import { HealthSystemDetailsData } from './types'
import './styles.scss'
import HandleGpo from './HandleGpo'

export const HealthSystemDetails = () => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [data, setData] = useState<HealthSystemDetailsData>(
    initialHealthSystemDetails
  )
  const { id: hsId } = useParams()
  const dispatch = useDispatch()
  const status = data?.status

  useEffect(() => {
    if (hsId) {
      getDetails(hsId)
    }
  }, [hsId])

  const getDetails = useCallback(
    async (hsId: string) => {
      try {
        dispatch(setLoading(true))
        const { data } = await getHealthSystemDetails(hsId)
        setData(data)
      } finally {
        dispatch(setLoading(false))
      }
    },
    [hsId]
  )

  const onChangeCollapse = () => setIsCollapsed((prev) => !prev)

  return (
    <Container
      className="hs-info"
      title={data?.name}
      breadcrumbs={BREADCRUMBS(data?.name || '')}
    >
      <div className="hs-info__general">
        <div className="hs-info__header row align-center">
          <Typography.Headline6>General</Typography.Headline6>
          <Status.Default className={HEALTH_SYSTEM_STATUSES_CLASSNAME[status]}>
            {status}
          </Status.Default>
        </div>
        {!isCollapsed && (
          <div className="hs-info__general-content column">
            <div className="hs-info__grid">
              <FieldWithLabel title="Tax ID">{data?.tax_id}</FieldWithLabel>
              <FieldWithLabel
                className="hs-info__departments"
                title="Departments"
              >
                {data?.departments.map((i) => i.name).join(', ')}
              </FieldWithLabel>
              <FieldWithLabel title="Email">{data?.email}</FieldWithLabel>
              <FieldWithLabel title="Phone number">
                {fullFormatPhoneNumber(data?.phone_number)}
              </FieldWithLabel>
              <FieldWithLabel title="Address">
                {formatAddress(data?.address)}
              </FieldWithLabel>
            </div>
            <HandleGpo
              getDetails={getDetails}
              national_gpo={data.national_gpo}
            />
            {data?.vice_president && (
              <IconFullName
                className="column"
                firstName={data?.vice_president?.first_name}
                lastName={data?.vice_president?.last_name}
                roleName={ROLES_NAMES[data?.vice_president?.role]}
                phone={formatPhoneNumber(
                  data?.vice_president?.phone_number || ''
                )}
                email={data?.vice_president?.email}
                src={data?.vice_president?.avatar_logo?.file_preview}
                uuid={data?.vice_president?.uuid}
                onStartChat={onStartChat}
              />
            )}
          </div>
        )}
        <Button
          className="hs-info__show-btn"
          type={BUTTON_TYPES.LINK}
          onClick={onChangeCollapse}
          inline
        >
          {`${isCollapsed ? 'Show more' : 'Hide'} information`}
        </Button>
      </div>
      <HealthSystemUsers hsId={hsId as string} />
    </Container>
  )
}
