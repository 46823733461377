import { useMemo } from 'react'

import './styles.scss'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { UserListItem } from 'features/ContractDetails/ContractDetailsGeneral/ResponsibleUsers/UserList/UserListItem'
import { ROLES } from '../../../../Permission'

type Props = {
  vendor?: boolean
}

const UserList = ({ vendor }: Props) => {
  const contractDetailsContext = useContractDetailsContext()

  const { details } = contractDetailsContext.state

  const responsibleUsers = useMemo(() => {
    if (vendor) {
      const vcs = details?.vendor_responsibles?.find(
        (i) => i.role === ROLES.VENDOR_CONTRACT_STEWARD
      )
      if (!!vcs) {
        const vendorResp =
          details?.vendor_responsibles.filter(
            (i) => i.role !== ROLES.VENDOR_CONTRACT_STEWARD
          ) ?? []
        return [vcs, ...vendorResp]
      } else {
        return [...(details?.vendor_responsibles ?? [])]
      }
    } else {
      return [details.creator]
    }
  }, [details.creator, details?.vendor_responsibles, vendor])

  return (
    <div className="rfp-details-responsible__user-list">
      {responsibleUsers.map((user) => (
        <UserListItem key={user.uuid} user={user} />
      ))}
    </div>
  )
}

export default UserList
