import Fetcher from '../../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../../constants'
import { AddContractCategoryResponse } from './types'

const fetcher = new Fetcher({})

export const getCCForAssign = async (uuid: string) =>
  fetcher.request<unknown, AddContractCategoryResponse>({
    url: `${API_DICTIONARY.USERS}${uuid}/${API_DICTIONARY.OPTIONS}${API_DICTIONARY.CONTRACT_CATEGORIES}`,
    method: HTTP_METHODS.GET
  })

export const sendCCForAssign = async (userId: string, uuid: string) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.USERS}${userId}/${API_DICTIONARY.CONTRACT_CATEGORIES}${uuid}/${API_DICTIONARY.CREATE_RELATION}`,
    method: HTTP_METHODS.POST
  })
