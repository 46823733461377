import { FC } from 'react'

import { Typography } from 'components/Typography'
import RadioGroup from 'components/RadioGroup/RadioGroup'
import { IRFPVendor_old } from 'features/RFP/RFPSurvey/types'
import { SURVEY_VENDORS_RATING_RANGE } from 'features/RFP/RFPSurvey/CreateSurvey/constants'
import { useRFPCreateSurveyContext } from 'features/RFP/RFPSurvey/CreateSurvey/Providers/RFPCreateSurveyContextProvider'
import { useRFPSurveyContext } from 'features/RFP/RFPDetails/Providers/RFPSurveyContextProvider'

type Props = { vendor: IRFPVendor_old; disabled?: boolean }

const VendorRating: FC<Props> = (props) => {
  const { vendor, disabled } = props

  const rfpSurveyContext = useRFPSurveyContext()
  const rfpCreateSurveyContext = useRFPCreateSurveyContext()

  const { isSurveySubmitted, vendorsRating } = rfpSurveyContext.state

  const {
    getLocalVendorRatingByUuid,
    hasVendorRatingErrorByUuid,
    handleLocalVendorRateChange
  } = rfpCreateSurveyContext.actions

  return (
    <div className="survey-vendor-rating">
      <Typography.Headline6>{vendor.name}</Typography.Headline6>
      <RadioGroup.RatingRange
        rate={
          isSurveySubmitted
            ? vendorsRating[vendor.uuid]
            : getLocalVendorRatingByUuid(vendor.uuid)
        }
        onRateChange={handleLocalVendorRateChange(vendor.uuid)}
        invalid={hasVendorRatingErrorByUuid(vendor.uuid)}
        range={SURVEY_VENDORS_RATING_RANGE}
        disabled={disabled}
        leastLabel="Not preferred at all"
        mostLabel="Most preferred"
      />
    </div>
  )
}

export default VendorRating
