import Fetcher from '../../../../services/Fetcher'
import { API_DICTIONARY, HTTP_METHODS } from '../../../../constants'
import { HealthSystemDetailsData, TNationalGpoUpdateRequest } from './types'

const fetcher = new Fetcher({})

export const getHealthSystemDetails = async (uuid: string) =>
  fetcher.request<unknown, HealthSystemDetailsData>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.HEALTH_SYSTEMS}${uuid}/`,
    method: HTTP_METHODS.GET
  })

export const updateNationalGpoName: TNationalGpoUpdateRequest = async (
  uuid,
  data
) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.MY_COMMUNITY}${API_DICTIONARY.HEALTH_SYSTEMS}${uuid}/${API_DICTIONARY.NATIONAL_GPO}`,
    method: HTTP_METHODS.POST,
    data
  })
