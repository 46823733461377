import { AddAmendmentPopup } from 'features/ContractDetails/HSList/AddAmendment/Popup'
import { HSTable } from 'features/ContractDetails/HSList/HSTable/HSTable'
import { useHSTable } from 'features/ContractDetails/HSList/hooks/useHSTable'
import HSTableProvider from 'features/ContractDetails/HSList/Providers/HSTableProvider'
import { getContractHealthSystems } from 'features/ContractDetails/HSList/api'
import { columnsShortView, onboardedHsColumns } from './HSTable/constants'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'

const HSList = () => {
  const { state } = useContractDetailsContext()
  const hsTableProps = useHSTable({ getContractHealthSystems })

  return (
    <HSTableProvider {...hsTableProps}>
      <div className="contract-hs-list">
        <HSTable
          columns={state.isOnboarded ? onboardedHsColumns : columnsShortView}
          {...hsTableProps}
        />
      </div>
      <AddAmendmentPopup />
    </HSTableProvider>
  )
}

export default HSList
