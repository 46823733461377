import { Typography, TYPOGRAPHY_WEIGHT } from '../../../components/Typography'
import { Input } from '../../../components/Input'
import { BTN_TXT, PLACEHOLDERS } from '../../../constants'
import { subject } from '@casl/ability'
import AddAction from './AddAction'
import DeleteAction from './DeleteAction'
import { Table } from '../../../components/Table'
import { columns } from './constants'
import { isTableHasParams } from '../../../helper/common'
import { useDispatch } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import {
  THealthSystemTableRequestData,
  THealthSystemUsersParams
} from '../../HealthSystemUsers/types'
import { DEFAULT_PAGE } from '../../../components/Table/constants'
import { useParams } from 'react-router-dom'
import axios, { CancelTokenSource } from 'axios'
import { useDebounce } from '../../../hooks/hooks'
import { setLoading } from '../../../redux/store/common/slice'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import { ReactComponent as Search16 } from 'assets/svg/Search16.svg'
import {
  TContactFacilityUsersFiltersFunc,
  TContractFacilityUsersData,
  TContractFacilityUsersFilters
} from './types'
import { getFacilityUsers } from '../api'
import {
  Can,
  useContractCategoryDetailsAbility
} from '../Providers/ContractCategoryDetailsAbilityProvider'
import { useContractCategoryDetailsPopup } from '../Providers/ContractCategoryDetailsPopupProvider'
import { useContractCategoryDetailsContext } from '../Providers/ContractCategoryDetailsContextProvider'
import { ACTIONS, SUBJECTS } from '../../Permission'
import { contractCategorySubject } from '../Abilities'

const FacilityUsers = () => {
  const ability = useContractCategoryDetailsAbility()
  const { addFacilityUsersPopup } = useContractCategoryDetailsPopup()
  const dispatch = useDispatch()
  const ccDetailsContext = useContractCategoryDetailsContext()
  const { actions, state } = ccDetailsContext
  const { getCCDetailsAsync } = actions
  const { details, canManageFacilityUsers } = state
  const [totalNumber, setTotalNumber] = useState(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState<
    TContractFacilityUsersData[]
  >([])
  const [searchValue, setSearchValue] = useState('')
  const [tableData, setTableData] = useState<TContractFacilityUsersData[]>([])
  const [sortParams, setSortParams] = useState<THealthSystemTableRequestData>()
  const [filters, setFilters] = useState<TContractFacilityUsersFilters>({})
  const [firstLoad, setFirstLoad] = useState(true)
  const [pageInfo, setPageInfo] = useState({ ...DEFAULT_PAGE })

  const { id } = useParams()

  const tableDataTokenRef = useRef<CancelTokenSource>()
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const debouncedSearch = useDebounce(searchValue, 500)
  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(
      () => {
        getTableData({ ...sortParams, ...filters, page: { ...DEFAULT_PAGE } })
        setPageInfo({ ...DEFAULT_PAGE })
      },
      firstLoad ? 0 : 500
    )
  }, [debouncedSearch])

  useEffect(() => {
    return () => {
      if (!!tableDataTokenRef.current) {
        tableDataTokenRef.current.cancel()
      }
    }
  }, [])

  const getTableData = ({
    sortField,
    sortOrder,
    filters,
    page
  }: THealthSystemTableRequestData) => {
    dispatch(setLoading(true))
    if (firstLoad) {
      setFirstLoad(false)
    }
    if (!!tableDataTokenRef.current) {
      tableDataTokenRef.current.cancel()
    }
    const params: THealthSystemUsersParams = { ...filters }
    if (sortField) {
      if (sortOrder) {
        params.ordering = `${sortOrder === 'descend' ? '-' : ''}${sortField}`
      }
      setSortParams({
        sortField,
        sortOrder
      })
    }
    if (searchValue) {
      params.search = searchValue
    }
    const dataPage = page ? page : pageInfo
    params.limit = dataPage.pageSize
    params.offset = (dataPage.pageNumber - 1) * dataPage.pageSize
    tableDataTokenRef.current = axios.CancelToken.source()
    getFacilityUsers(id as string, {
      cancelToken: tableDataTokenRef.current?.token,
      params
    })
      .then((resp: any) => {
        if (!resp.data?.results) {
          return
        }
        setTableData(
          resp.data.results.map((i) => ({
            ...i,
            key: i.uuid
          }))
        )
        setSelectedRowKeys([])
        setSelectedRows([])
        setTotalNumber(resp.data.count)
        getCCDetailsAsync()
      })
      .finally(() => dispatch(setLoading(false)))
  }

  const applyFilters: TContactFacilityUsersFiltersFunc = (
    field,
    appliedFilters
  ) => {
    const newFilters = { ...filters, [field]: appliedFilters }
    setFilters(newFilters)
    getTableData({ ...sortParams, filters: newFilters })
  }
  useEffect(() => {
    setLoading(false)
  }, [tableData[0]?.uuid as string])

  const handleTableChange = (pagination, _filters, sorter) => {
    const page = {
      pageNumber: pagination.current,
      pageSize: pagination.pageSize
    }
    getTableData({
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
      page
    })
    setPageInfo(page)
  }
  const handleSelectRow = (selectedRowKeys, selectedRow) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRow)
  }

  const handleChangePageSize = (pageSize) => {
    const page = {
      pageNumber: 1,
      pageSize
    }
    getTableData({
      ...sortParams,
      filters,
      page
    })
    setPageInfo(page)
  }

  return (
    <div className="table-wrapper">
      <div className="table-wrapper__header">
        {!!selectedRowKeys.length ? (
          <Typography.Body1
            className="table-wrapper__select-title"
            weight={TYPOGRAPHY_WEIGHT.SEMI_BOLD}
          >
            Selected {selectedRowKeys.length} of {totalNumber}
          </Typography.Body1>
        ) : (
          <Input
            className="allow-clear"
            propsInput={{
              allowClear: { clearIcon: <Close16 /> },
              className: 'rfp-hs-users_search',
              placeholder: PLACEHOLDERS.SEARCH,
              prefix: <Search16 />,
              value: searchValue,
              onChange: (e) => setSearchValue(e.target.value)
            }}
          />
        )}
        <div className="table-wrapper__header_buttons">
          <Can
            I={ACTIONS.MANAGE}
            a={contractCategorySubject(SUBJECTS.FACILITY_USERS, { ...details })}
          >
            <>
              <AddAction
                updateAssignedValues={() =>
                  getTableData({ ...sortParams, ...filters })
                }
                selected={selectedRowKeys}
              />
              <DeleteAction
                updateAssignedValues={() =>
                  getTableData({ ...sortParams, ...filters })
                }
                selected={selectedRowKeys}
                selectedUsers={selectedRows}
              />
            </>
          </Can>
        </div>
      </div>
      <Table<TContractFacilityUsersData>
        dataSource={tableData}
        columns={columns({
          applyFilters,
          filters,
          ccId: id || ''
        })}
        pageSize={pageInfo.pageSize}
        pagination={{
          pageSize: pageInfo.pageSize,
          current: pageInfo.pageNumber,
          total: totalNumber
        }}
        className="rfp-vendors-data-list-table"
        onChangePage={handleChangePageSize}
        onChange={handleTableChange}
        hasSearchOrFilters={isTableHasParams<TContractFacilityUsersFilters>(
          debouncedSearch,
          filters
        )}
        createButton={
          ability.can(
            ACTIONS.MANAGE,
            subject(SUBJECTS.FACILITY_USERS, { ...details })
          )
            ? {
                buttonText: BTN_TXT.ADD_USER,
                availability: true,
                action: addFacilityUsersPopup.actions.open
              }
            : undefined
        }
        rowSelection={
          canManageFacilityUsers
            ? {
                selectedRowKeys,
                onChange: handleSelectRow,
                getCheckboxProps: (record) => ({
                  ...record,
                  disabled: record.uuid === details.owner?.uuid
                })
              }
            : undefined
        }
      />
    </div>
  )
}

export default FacilityUsers
