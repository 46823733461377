import { ColumnsType } from 'antd/es/table'
import { Dropdown } from 'components/Dropdown'
import { ROLES_NAMES } from 'constants/common'
import { ReactComponent as Filter16 } from 'assets/svg/Filter16.svg'
import { ReactComponent as FilterApplied16 } from 'assets/svg/FilterApplied16.svg'
import { fetchLedBy } from 'pages/CRUDUser/api'
import { LeadByIndicator } from '../../components/LeadByIndicator'
import { Sorter } from '../../components/Sorter/Sorter'
import { ColumnType, SortOrder } from 'antd/es/table/interface'
import {
  TCCListColumnsFunc,
  TCCListFiltersFunc,
  TContractCategoriesList,
  TContractsCategoriesListFilters
} from './types'
import { optionFormatter } from '../Contracts/helpers'
import IconNameItem from '../../components/IconNameItem/IconNameItem'
import CategoriesPopover from './CategoriesPopover'
import { currencyFormatter } from '../ContractDetails/ContractDetailsGeneral/ContractDetailsForm/utils'
import { ReactComponent as WarningIcon } from 'assets/svg/WarningRed16.svg'
import { Tooltip } from '../../components/Tooltip/Tooltip'
import { TOOLTIP } from '../../constants'

const _nameCol: ColumnType<TContractCategoriesList> = {
  dataIndex: 'name',
  key: 'name',
  width: 284,
  title: ({ sortOrder, sortColumn }) => (
    <Sorter<TContractCategoriesList>
      dataIndex={'name'}
      sortColumn={sortColumn}
      sortOrder={sortOrder as SortOrder}
    >
      Contract category
    </Sorter>
  ),
  sorter: true,
  showSorterTooltip: false
}

export const columns = ({
  applyFilters,
  filters,
  userUuid
}: TCCListColumnsFunc): ColumnsType<TContractCategoriesList> => [
  {
    ..._nameCol,
    render: (_, { name, uuid, led_by }) => (
      <CategoriesPopover name={name} uuid={uuid} led_by={led_by} />
    )
  },
  {
    dataIndex: 'led_by',
    key: 'led_by',
    width: 160,
    title: 'Led by',
    className: 'vertical-align-middle',
    render: (_, { led_by }) => (
      <div className="flex column gap-16">
        {!!led_by?.length ? (
          led_by?.map(({ led_by }) => (
            <div className="separate-item row align-cente">
              {led_by ? <LeadByIndicator value={led_by} /> : '-'}
            </div>
          ))
        ) : (
          <div className=" row align-center">-</div>
        )}
      </div>
    ),
    showSorterTooltip: false,
    filterDropdown: (props) => (
      <Dropdown.Filter<
        TContractsCategoriesListFilters,
        TCCListFiltersFunc,
        unknown
      >
        applyFilters={applyFilters}
        appliedFilters={filters}
        searchCallback={fetchLedBy}
        field="led_by"
        asyncSearch
        optionFormatter={optionFormatter}
        {...props}
      />
    ),
    filterIcon: !!filters?.led_by?.length ? <FilterApplied16 /> : <Filter16 />,
    filterSearch: true
  },
  {
    dataIndex: 'responsible',
    key: 'responsible',
    width: 235,
    title: 'Responsible',
    render: (_, { led_by }) => (
      <div className="flex column gap-16">
        {!!led_by?.length ? (
          led_by?.map(({ responsible }) => (
            <div className="separate-item categories-responsible">
              <IconNameItem
                firstName={responsible.first_name}
                className={'responsible'}
                lastName={responsible.last_name}
                src={responsible.avatar?.file_preview || null}
                roleName={ROLES_NAMES[responsible.role]}
              />
            </div>
          ))
        ) : (
          <div className=" row align-center">-</div>
        )}
      </div>
    )
  },
  {
    dataIndex: 'compliance_requirement',
    key: 'compliance_requirement',
    width: 235,
    title: 'Compliance requirement, %',
    render: (_, { led_by }) => (
      <div className="flex column gap-16">
        {!!led_by?.length ? (
          led_by?.map(({ compliance_requirement, responsible }) => (
            <div className="separate-item row align-center">
              {!!Number(compliance_requirement) ? (
                compliance_requirement
              ) : responsible?.uuid === userUuid ? (
                <Tooltip title={TOOLTIP.COMPLIANCE_CC_EMPTY}>
                  <WarningIcon />
                </Tooltip>
              ) : (
                '-'
              )}
            </div>
          ))
        ) : (
          <div className=" row align-center">-</div>
        )}
      </div>
    )
  },
  {
    dataIndex: 'expected_spend',
    key: 'expected_spend',
    width: 235,
    title: 'Expected spend, $',
    render: (_, { led_by }) => (
      <div className="flex column gap-16">
        {!!led_by?.length ? (
          led_by?.map(({ expected_spend }) => (
            <div className="separate-item row align-center">
              {!!expected_spend ? currencyFormatter(expected_spend) : '-'}
            </div>
          ))
        ) : (
          <div className=" row align-center">-</div>
        )}
      </div>
    )
  },
  {
    dataIndex: 'expected_savings',
    key: 'expected_savings',
    width: 235,
    title: 'Expected savings, $',
    render: (_, { led_by }) => (
      <div className="flex column gap-16">
        {!!led_by?.length ? (
          led_by?.map(({ expected_savings, responsible }) => (
            <div className="separate-item row align-center">
              {!!expected_savings ? (
                currencyFormatter(expected_savings)
              ) : responsible?.uuid === userUuid ? (
                <Tooltip title={TOOLTIP.EXPECTED_SAVINGS_CC_EMPTY}>
                  <WarningIcon />
                </Tooltip>
              ) : (
                '-'
              )}
            </div>
          ))
        ) : (
          <div className=" row align-center">-</div>
        )}
      </div>
    )
  }
]

export const cpColumns: ColumnsType<TContractCategoriesList> = [_nameCol]
