import { Select } from '../../../../../components/Select'
import { LABELS, PLACEHOLDERS } from '../../../../../constants'
import { TOption } from '../../../../../components/Select/types'
import './styles.scss'
import { Button, BUTTON_TYPES } from '../../../../../components/Button'
import { ReactComponent as Close } from 'assets/svg/CloseIconDrawer.svg'

interface IProps {
  vendorOptions: TOption[]
  onChangeVendor: (vendorValue: string) => void
  vendorValue?: string
  ccOptions: TOption[]
  onChangeCC: (ccValues: string[]) => void
  ccValues?: string[]
  disabled?: boolean
  deleteVisible?: boolean
  deletePress?: () => void
}

const SingleVendorCCSelection = ({
  vendorOptions,
  onChangeVendor,
  vendorValue,
  ccOptions,
  onChangeCC,
  ccValues,
  disabled,
  deleteVisible,
  deletePress
}: IProps) => {
  return (
    <div className="flex row full-width mb-24 mt-24 align-end">
      <Select
        className="rfp-survey-vendor-picker"
        options={vendorOptions}
        propsItem={{
          label: LABELS.SELECT_VENDOR_FOR_SURVEY
        }}
        propsSelect={{
          getPopupContainer: (node) => document?.body ?? node,
          placeholder: PLACEHOLDERS.PLEASE_SELECT,
          onChange: onChangeVendor,
          value: vendorValue,
          disabled
        }}
      />
      <Select.Multi
        className="full-width ml-16"
        options={ccOptions}
        propsItem={{
          label: LABELS.CONTRACT_CATEGORIES
        }}
        propsSelect={{
          getPopupContainer: (node) => document?.body ?? node,
          placeholder: PLACEHOLDERS.PLEASE_SELECT,
          onChange: onChangeCC,
          value: ccValues,
          disabled: disabled || !vendorValue
        }}
      />
      {deleteVisible && (
        <Button
          className="ml-16"
          icon={<Close />}
          type={BUTTON_TYPES.LINK}
          onClick={deletePress}
          disabled={!deleteVisible}
          upperCase
        />
      )}
    </div>
  )
}

export default SingleVendorCCSelection
