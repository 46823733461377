import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  IRFPSurveyCreateRequestPayload,
  IRFPVendorCCPayload,
  RFPVendorRating,
  RFPVendorValue_old
} from 'features/RFP/RFPSurvey/types'
import { useRFPSurveyContext } from 'features/RFP/RFPDetails/Providers/RFPSurveyContextProvider'
import { useRFPDetailsPopupContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsPopupProvider'
import { useAbility } from '@casl/react'
import { AbilityContext, ACTIONS, SUBJECTS } from 'features/Permission'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { subject } from '@casl/ability'
import { RFP_STATUSES } from 'features/RFP/constants'
import { notification } from 'components/Notification'
import { VALIDATION_MESSAGES } from 'constants/txt'
import { idValueSettings, RFP_DEFAULT_VENDOR_LINE } from '../constants'
import { returnSelectOptions } from '../../../../../helper/common'
import { TOption } from '../../../../../components/Select/types'
import { TOptionsWithNameResponse } from '../../../../../constants'

type ContextProps = {
  state: {
    canCreateOrEditSurvey: boolean
    isSurveyInfoFilled: boolean
    localSelectedVendors: IRFPVendorCCPayload[]
    localAvailableVendors: IRFPVendorCCPayload[]
    localSelectedPickersValues: Partial<IRFPSurveyCreateRequestPayload>[]
  }
  actions: {
    addVendorCCLine: VoidFunction
    removeVendorCCLine: (lineIndex: number) => void
    updateVendorValue: (lineIndex: number, vendor?: string) => void
    updateCCValue: (
      lineIndex: number,
      vendor: string,
      category?: string[]
    ) => void
    getSpecificVendorPickerOptions: (vendorId?: string) => TOption[]
    getSpecificCCPickerOptions: (vendorId?: string) => TOption[]
    handleCreateSurvey: VoidFunction
    handleSubmitSurvey: VoidFunction
    handelCancelSurveyVoting: VoidFunction
    handelCancelSurveyCreation: VoidFunction
    getLocalVendorRatingByUuid: (uuid: string) => number
    hasVendorRatingErrorByUuid: (uuid: string) => boolean
    handleLocalVendorRateChange: (
      uuid: RFPVendorValue_old
    ) => (rate: number) => void
  }
}

const RFPCreateSurveyContext = createContext<ContextProps>({
  state: {} as ContextProps['state'],
  actions: {} as ContextProps['actions']
})

const RFPCreateSurveyContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const ability = useAbility<any>(AbilityContext)

  const rfpDetailsContext = useRFPDetailsContext()
  const rfpSurveyContext = useRFPSurveyContext()
  const { createSurveyPopup, submitSurveyPopup } = useRFPDetailsPopupContext()

  const { data } = rfpDetailsContext.state
  const { vendors, vendorsRating, contractCategories, selectedVendorValues } =
    rfpSurveyContext.state
  const {
    submitSurveyVotingAsync,
    getRfpVendorCCListAsync,
    createSurveyAsync
  } = rfpSurveyContext.actions

  const defaultPickerValue = useMemo(
    () =>
      selectedVendorValues.length
        ? selectedVendorValues
        : [RFP_DEFAULT_VENDOR_LINE],
    [selectedVendorValues]
  )

  const [localSelectedPickersValues, _setLocalSelectedPickersValues] =
    useState<Partial<IRFPSurveyCreateRequestPayload>[]>(defaultPickerValue)

  const [_localVendorsRating, _setLocalVendorsRating] =
    useState<RFPVendorRating>(vendorsRating)

  const [_vendorsRatingWithError, _setVendorsRatingWithError] = useState<
    Array<TOptionsWithNameResponse['uuid']>
  >([])

  const localSelectedVendors = useMemo(() => {
    const selectedIds = localSelectedPickersValues
      .map((pickers) => pickers.vendor)
      .filter((vendor) => !!vendor)
    return vendors.filter((vendor) => selectedIds.includes(vendor.id))
  }, [localSelectedPickersValues, vendors])

  const isSurveyInfoFilled = useMemo(
    () =>
      localSelectedPickersValues.reduce((acc, val) => {
        if (!acc) return acc
        return !!val.vendor && !!val.category?.length
      }, true),
    [localSelectedPickersValues]
  )

  const localAvailableVendors = useMemo(
    () =>
      vendors
        ? vendors.filter(
            (vendor) =>
              !localSelectedVendors
                .map((vendor) => vendor.id)
                .includes(vendor.id)
          )
        : [],
    [localSelectedVendors, vendors]
  )

  const addVendorCCLine = useCallback(
    () =>
      _setLocalSelectedPickersValues((prev) => [
        ...prev,
        RFP_DEFAULT_VENDOR_LINE
      ]),
    []
  )

  const removeVendorCCLine = useCallback(
    (lineIndex: number) =>
      _setLocalSelectedPickersValues((prev) =>
        prev.filter((_, idx) => idx !== lineIndex)
      ),
    []
  )

  const updateVendorValue = useCallback(
    async (lineIndex: number, vendor?: string) => {
      _setLocalSelectedPickersValues((prev) =>
        prev.map((item, idx) => {
          let prevValue = { ...item }
          if (idx === lineIndex) {
            prevValue = { ...prevValue, vendor }
          }
          return prevValue
        })
      )
      if (vendor) {
        await getRfpVendorCCListAsync(vendor)
      }
    },
    [getRfpVendorCCListAsync]
  )

  const updateCCValue = useCallback(
    async (lineIndex: number, vendor: string, category?: string[]) =>
      _setLocalSelectedPickersValues((prev) =>
        prev.map((item, idx) => {
          let prevValue = { ...item }
          if (idx === lineIndex && item.vendor === vendor) {
            prevValue = { ...prevValue, category }
          }
          return prevValue
        })
      ),
    []
  )

  const getSpecificVendorPickerOptions = useCallback(
    (selectedVendorId?: string) => {
      const pickerOptions: IRFPVendorCCPayload[] = []
      if (selectedVendorId) {
        const selectedVendorOption = vendors.find(
          (vendor) => vendor.id === selectedVendorId
        )
        if (selectedVendorOption) {
          pickerOptions.push(selectedVendorOption)
        }
      }
      pickerOptions.push(...localAvailableVendors)
      return returnSelectOptions(pickerOptions, idValueSettings)
    },
    [localAvailableVendors, vendors]
  )

  const getSpecificCCPickerOptions = useCallback(
    (selectedVendorId?: string) => {
      const pickerOptions: IRFPVendorCCPayload[] = []
      if (selectedVendorId && contractCategories[selectedVendorId]) {
        pickerOptions.push(...contractCategories[selectedVendorId])
      }
      return returnSelectOptions(pickerOptions, idValueSettings)
    },
    [contractCategories]
  )

  const canCreateOrEditSurvey = useMemo(
    () =>
      ability.can(ACTIONS.CREATE, subject(SUBJECTS.SURVEY, { ...data })) &&
      data.status === RFP_STATUSES.PRICE_FILES_ANALYSIS,
    [ability, data]
  )

  const getLocalVendorRatingByUuid = useCallback(
    (uuid: RFPVendorValue_old) => _localVendorsRating[uuid],
    [_localVendorsRating]
  )

  const hasVendorRatingErrorByUuid = useCallback(
    (uuid: RFPVendorValue_old) => _vendorsRatingWithError.includes(uuid),
    [_vendorsRatingWithError]
  )

  const _updateLocalSelectedPickerValues = useCallback(() => {
    _setLocalSelectedPickersValues(defaultPickerValue)
  }, [defaultPickerValue])

  const _updateLocalVendorRating = useCallback(() => {
    _setLocalVendorsRating(vendorsRating)
  }, [vendorsRating])

  const _getUnratedVendors = useCallback(() => {
    return vendors.filter((v) => !_localVendorsRating[v.id])
  }, [_localVendorsRating, vendors])

  const handleLocalVendorRateChange = useCallback(
    (uuid: RFPVendorValue_old) => (rate: number) => {
      _setVendorsRatingWithError((prevState) =>
        prevState.filter((e) => e !== uuid)
      )

      _setLocalVendorsRating((prevState) => ({
        ...prevState,
        [uuid]: rate
      }))
    },
    []
  )

  const _hasValidationError = useCallback(() => {
    const selectedCategoriesSet = new Set(
      localSelectedPickersValues.map((picker) => picker.category).flat()
    )

    return (
      localSelectedVendors.length <= 0 ||
      data.contract_categories.some((c) => !selectedCategoriesSet.has(c.uuid))
    )
  }, [
    data.contract_categories,
    localSelectedPickersValues,
    localSelectedVendors.length
  ])

  const handleCreateSurvey = useCallback(async () => {
    if (_hasValidationError()) {
      notification.error({ message: VALIDATION_MESSAGES.SM0042 })
      return
    }
    // checking that partial type is fully filled (not partial)
    if (isSurveyInfoFilled) {
      createSurveyAsync(
        localSelectedPickersValues as IRFPSurveyCreateRequestPayload[]
      )
    }
  }, [
    _hasValidationError,
    isSurveyInfoFilled,
    createSurveyAsync,
    localSelectedPickersValues
  ])

  const handleSubmitSurvey = useCallback(async () => {
    if (vendors.length !== Object.keys(_localVendorsRating).length) {
      const unratedVendorsUuid = _getUnratedVendors().map((v) => v.id)

      _setVendorsRatingWithError(unratedVendorsUuid)
      return
    }

    submitSurveyVotingAsync(_localVendorsRating)
  }, [
    _getUnratedVendors,
    _localVendorsRating,
    submitSurveyVotingAsync,
    vendors.length
  ])

  const handelCancelSurveyVoting = useCallback(() => {
    _updateLocalVendorRating()

    _setVendorsRatingWithError([])

    submitSurveyPopup.actions.close()
  }, [_updateLocalVendorRating, submitSurveyPopup.actions])

  const handelCancelSurveyCreation = useCallback(() => {
    createSurveyPopup.actions.close()
    _updateLocalSelectedPickerValues()
  }, [_updateLocalSelectedPickerValues, createSurveyPopup.actions])

  useEffect(() => {
    _updateLocalSelectedPickerValues()
  }, [_updateLocalSelectedPickerValues])

  useEffect(() => {
    if (localSelectedVendors.length) {
      localSelectedVendors.forEach(async (vendor) => {
        // get CC called only in case when there's no data available
        await getRfpVendorCCListAsync(vendor.id)
      })
    }
  }, [getRfpVendorCCListAsync, localSelectedVendors])

  useEffect(() => {
    if (_vendorsRatingWithError.length > 0) {
      notification.error({ message: VALIDATION_MESSAGES.SM0039 })
    }
  }, [_vendorsRatingWithError])

  const state = useMemo(
    () => ({
      canCreateOrEditSurvey,
      isSurveyInfoFilled,
      localSelectedVendors,
      localAvailableVendors,
      localSelectedPickersValues
    }),
    [
      canCreateOrEditSurvey,
      isSurveyInfoFilled,
      localSelectedVendors,
      localAvailableVendors,
      localSelectedPickersValues
    ]
  )

  const actions = useMemo(
    () => ({
      addVendorCCLine,
      removeVendorCCLine,
      updateVendorValue,
      updateCCValue,
      getSpecificVendorPickerOptions,
      getSpecificCCPickerOptions,
      getLocalVendorRatingByUuid,
      handleCreateSurvey,
      handleSubmitSurvey,
      handelCancelSurveyVoting,
      handelCancelSurveyCreation,
      handleLocalVendorRateChange,
      hasVendorRatingErrorByUuid
    }),
    [
      addVendorCCLine,
      removeVendorCCLine,
      updateVendorValue,
      updateCCValue,
      getSpecificVendorPickerOptions,
      getSpecificCCPickerOptions,
      getLocalVendorRatingByUuid,
      handelCancelSurveyVoting,
      handelCancelSurveyCreation,
      handleCreateSurvey,
      handleLocalVendorRateChange,
      handleSubmitSurvey,
      hasVendorRatingErrorByUuid
    ]
  )
  return (
    <RFPCreateSurveyContext.Provider value={{ state, actions }}>
      {children}
    </RFPCreateSurveyContext.Provider>
  )
}

export const useRFPCreateSurveyContext = () =>
  useContext(RFPCreateSurveyContext)

export default RFPCreateSurveyContextProvider
