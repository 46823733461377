import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from 'redux/store/user/getters'
import { getInvoiceDataTemplate } from '../api'
import useTabs, {
  TabsReturnActions,
  TabsReturnState
} from '../../../hooks/useTabs'
import { ROLES } from 'features/Permission'
import { TRFPDocument } from '../../../features/RFP/RFPDetails/types'
import { TInvoiceDataResult } from '../../../features/InvoiceData/types'
import { INVOICE_DATA_TABS } from '../../../features/InvoiceData/constants'

type ContextProps = {
  state: {
    files: TRFPDocument[]
  } & TabsReturnState
  actions: {} & TabsReturnActions
}
const InvoiceDataContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const InvoiceDataContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const [files, setFiles] = useState<TInvoiceDataResult[]>([])
  const { tab } = useParams()

  const { state: tabsState, actions: tabsActions } = useTabs({
    tabs: INVOICE_DATA_TABS,
    activeTab: tab || INVOICE_DATA_TABS[0].key
  })

  useEffect(() => {
    if (!!tab) tabsActions.setActiveTab(tab)
  }, [tab])

  useEffect(() => {
    if (user.role !== ROLES.COMMUNITY_PRESIDENT)
      getInvoiceDataTemplate().then((res) =>
        setFiles(
          res.data.results.map((i, idx) => ({
            ...i,
            uuid: `${idx}`
          }))
        )
      )
  }, [dispatch])

  const context = useMemo(
    () => ({
      state: {
        files,
        ...tabsState
      },
      actions: { ...tabsActions }
    }),
    [files, tabsState, tabsActions]
  )

  return (
    <InvoiceDataContext.Provider value={context}>
      {children}
    </InvoiceDataContext.Provider>
  )
}

export const useInvoiceDataContext = () => useContext(InvoiceDataContext)

export default InvoiceDataContextProvider
