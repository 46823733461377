import cn from 'classnames'
import { Form, Select as SelectAntd } from 'antd'
import { ReactComponent as ChevronDown16 } from 'assets/svg/ChevronDown16.svg'
import { ReactComponent as SelectClearIcon } from 'assets/svg/SelectClear.svg'
import { ReactComponent as CheckBoxIconFilled } from 'assets/svg/CheckBoxSelect.svg'
import { ReactComponent as CheckboxOutlineBlank } from 'assets/svg/CheckboxOutlineBlank.svg'
import { ReactComponent as Close16 } from 'assets/svg/Clos16.svg'
import { Props } from './types'
import './select.multi.scss'

const checkOnEmpty = (arr: any[]) => {
  return !!arr?.find((i) => !!i?.value || !!i?.options?.length)
}

const { Option, OptGroup } = SelectAntd

const Multi = ({ ...props }: Props): JSX.Element => (
  <Form.Item
    className={cn('custom-select', 'select-multi', props.className)}
    colon={false}
    {...props.propsItem}
    name={
      props.options.length && checkOnEmpty(props.options)
        ? props.propsItem?.name
        : ''
    }
  >
    <SelectAntd
      getPopupContainer={(node) => node}
      {...props.propsSelect}
      mode="multiple"
      maxTagCount="responsive"
      placement="bottomLeft"
      dropdownClassName="custom-select__dropdown"
      suffixIcon={<ChevronDown16 />}
      clearIcon={<SelectClearIcon />}
      removeIcon={<Close16 />}
      menuItemSelectedIcon={<CheckBoxIconFilled />}
      maxTagPlaceholder={(values) => `+${values.length}`}
      filterOption={(input, option) =>
        option?.props?.children[1]?.props?.children
          ?.toLowerCase()
          .includes(input?.toLowerCase())
      }
      showArrow
      showSearch
    >
      {props.options.map((option) =>
        !option?.options ? (
          <Option
            value={option.value}
            disabled={option?.disabled}
            className="select-multi_option"
            key={option.value}
          >
            <CheckboxOutlineBlank className="select-multi_blank-checkbox" />
            <div className="select-multi_option-text">{option.label}</div>
          </Option>
        ) : (
          <OptGroup label={option.label} key={option.key}>
            {option.options.map((i) => (
              <Option
                className="select-multi_option"
                value={i.value}
                key={i.value}
              >
                <CheckboxOutlineBlank className="select-multi_blank-checkbox" />
                <div className="select-multi_option-text">{i.label}</div>
              </Option>
            ))}
          </OptGroup>
        )
      )}
    </SelectAntd>
  </Form.Item>
)

export default Multi
