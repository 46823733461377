import { FC, useState } from 'react'
import './styles.scss'
import { Typography } from 'components/Typography'
import { Button, BUTTON_TYPES } from 'components/Button'
import { ReactComponent as Delete16 } from 'assets/svg/Delete16.svg'
import {
  ACTIONS,
  contractSubject,
  SUBJECTS
} from 'features/ContractDetails/Abilities'
import { ROLES_NAMES } from 'constants/common'
import { Avatar } from 'components/Avatar/Avatar'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import { useContractDetailsPopup } from 'features/ContractDetails/Providers/ContractDetailsPopupProvider'
import { useContractDetailsAbility } from 'features/ContractDetails/Providers/ContractDetailsAbilityProvider'
import { ROLES } from 'features/Permission'
import { ReactComponent as ReassignIcon } from 'assets/svg/Reassign.svg'
import { Reassign } from 'features/Reassign/ReassignPopup'
import { useSelector } from 'react-redux'
import { getUser } from 'redux/store/user/getters'
import { Props } from './types'
import { isVendorReassignmentAllowed } from './helper'

export const UserListItem: FC<Props> = (props) => {
  const { user } = props

  const currentUser = useSelector(getUser)

  const ability = useContractDetailsAbility()
  const contractDetailsContext = useContractDetailsContext()
  const { deleteResponsibleUserPopup } = useContractDetailsPopup()
  const { getContractDetailsAsync } = contractDetailsContext.actions
  const [isOpenReassign, setIsOpenReassign] = useState(false)

  const { details, isVendor, isCommons } = contractDetailsContext.state
  const { open } = deleteResponsibleUserPopup.actions

  const name = user.first_name + ' ' + user.last_name
  const capitalCharName = `${user.first_name?.charAt(0) || ''}${
    user.last_name?.charAt(0) || ''
  }`.toUpperCase()

  const onDelete = () => {
    if (user) {
      open({ user })
    }
  }

  const canDeleteResponsibleUser =
    ability.can(
      ACTIONS.DELETE,
      contractSubject(SUBJECTS.RESPONSIBLE_USER, details)
    ) &&
    (isVendor
      ? user.role === ROLES.VENDOR_ANALYST || user.role === ROLES.VENDOR_LEGAL
      : isCommons
      ? user.role === ROLES.COMMONS_LEGAL || user.role === ROLES.COMMONS_ANALYST
      : user.role === ROLES.ANALYST || user.role === ROLES.LEGAL)

  const canReassignOwner = isVendorReassignmentAllowed({
    currentUser,
    user,
    details
  })

  return (
    <>
      <div className="rfp-details-responsible-user">
        <div className="rfp-details-responsible-user__user">
          <div className="rfp-details-responsible-user__avatar">
            <Avatar
              name={capitalCharName}
              alt="Responsible user image"
              src={user.avatar?.file_preview}
            />
          </div>
          <div className="rfp-details-responsible-user__content">
            <Typography.Body1 weight="semi-bold">{name}</Typography.Body1>
            <Typography.Caption className="rfp-details-responsible-user__role">
              {ROLES_NAMES[user.role]}
            </Typography.Caption>
          </div>
        </div>
        <div className="rfp-details-responsible-user__actions">
          {canDeleteResponsibleUser ? (
            <Button.Icon
              type={BUTTON_TYPES.LINK}
              icon={<Delete16 />}
              onClick={onDelete}
              small
              danger
            />
          ) : (
            canReassignOwner && (
              <Button.Icon
                type={BUTTON_TYPES.LINK}
                icon={<ReassignIcon />}
                onClick={() => setIsOpenReassign(true)}
                small
              />
            )
          )}
        </div>
      </div>
      {canReassignOwner && (
        <Reassign
          isRFP={false}
          isReassignPopupOpen={isOpenReassign}
          setIsReassignPopupOpen={setIsOpenReassign}
          ownerRole={user.role}
          ownerName={name}
          uuid={details?.uuid}
          isCommons={isCommons}
          update={getContractDetailsAsync}
        />
      )}
    </>
  )
}
