import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'
import { isNotInLedBy, isOwnerCondition } from './utils'
import { ROLES, ACTIONS, SUBJECTS } from 'features/Permission/constants'
import { IUser } from 'redux/store/user/types'
import {
  Conditions,
  ContractCategoryAbility,
  ContractCategoryAbilityBuilder,
  PossibleAbilities
} from 'features/ContractCategoryDetails/Abilities/types'
import { LED_BY_VALUE } from '../../../components/LeadByIndicator/constants'

const ContractCategoryAbilityClass =
  Ability as AbilityClass<ContractCategoryAbility>

export const ability = new Ability<PossibleAbilities, Conditions>()

export default (user: IUser) => {
  const builder = new AbilityBuilder(ContractCategoryAbilityClass)

  defineAbilities(builder, user)

  return new Ability(builder.rules) as ContractCategoryAbility
}

const defineAbilities = (
  builder: ContractCategoryAbilityBuilder,
  user: IUser
) => {
  builder.can(ACTIONS.ADD, SUBJECTS.RESPONSIBLE_USER, {
    ...isOwnerCondition(user)
  })
  builder.can(ACTIONS.DELETE, SUBJECTS.RESPONSIBLE_USER, {
    ...isOwnerCondition(user)
  })

  if (
    user.role === ROLES.VICE_PRESIDENT ||
    user.role === ROLES.COMMONS_VICE_PRESIDENT
  ) {
    builder.can(ACTIONS.EDIT, SUBJECTS.OWNER)
    builder.can(ACTIONS.VIEW, SUBJECTS.CONTRACT_CATEGORY)
    builder.can(ACTIONS.EDIT, SUBJECTS.CONTRACT_CATEGORY, {
      ...isOwnerCondition(user)
    })
    builder.can(ACTIONS.EDIT, SUBJECTS.RESPONSIBLE_USER, {
      ...isOwnerCondition(user)
    })
    builder.can(ACTIONS.MANAGE, SUBJECTS.HEALTH_SYSTEMS, {
      ...isOwnerCondition(user),
      ...isNotInLedBy([LED_BY_VALUE.HEALTH_SYSTEM, LED_BY_VALUE.NATIONAL_GPO])
    })
    builder.can(ACTIONS.MANAGE, SUBJECTS.FACILITY_USERS, isOwnerCondition(user))
  }
  if (user.role === ROLES.COMMUNITY_PRESIDENT) {
    builder.can(ACTIONS.VIEW, SUBJECTS.CONTRACT_CATEGORY)
  }
  if (
    user.role === ROLES.CONTRACT_STEWARD ||
    user.role === ROLES.COMMONS_CONTRACT_STEWARD
  ) {
    builder.can(ACTIONS.VIEW, SUBJECTS.CONTRACT_CATEGORY)
    builder.can(
      ACTIONS.EDIT,
      SUBJECTS.CONTRACT_CATEGORY,
      isOwnerCondition(user)
    )
    builder.can(ACTIONS.EDIT, SUBJECTS.RESPONSIBLE_USER, isOwnerCondition(user))
    builder.can(ACTIONS.MANAGE, SUBJECTS.FACILITY_USERS, isOwnerCondition(user))
    builder.can(ACTIONS.MANAGE, SUBJECTS.HEALTH_SYSTEMS, {
      ...isOwnerCondition(user),
      ...isNotInLedBy([LED_BY_VALUE.HEALTH_SYSTEM, LED_BY_VALUE.NATIONAL_GPO])
    })
  }
  if (
    user.role === ROLES.LEGAL ||
    user.role === ROLES.ANALYST ||
    user.role === ROLES.HOSPITAL_ADMIN ||
    user.role === ROLES.INFLUENCER ||
    user.role === ROLES.STAKEHOLDER ||
    user.role === ROLES.VENDOR_LEGAL ||
    user.role === ROLES.VENDOR_ANALYST ||
    user.role === ROLES.VENDOR_CONTRACT_STEWARD ||
    user.role === ROLES.VENDOR_FINANCE_OFFICER ||
    user.role === ROLES.VENDOR_CONTRACT_ADMIN ||
    user.role === ROLES.COMMONS_LEGAL ||
    user.role === ROLES.COMMONS_ANALYST ||
    user.role === ROLES.COMMONS_CLINICAL_COORDINATOR ||
    user.role === ROLES.COMMONS_STAKEHOLDER
  ) {
    builder.can(ACTIONS.VIEW, SUBJECTS.CONTRACT_CATEGORY)
  }
}
