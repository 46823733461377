import { FC, useCallback, useState } from 'react'
import { Form } from 'antd'

import './VendorSelection.scss'
import RFPVendorSelectionItem from './RFPVendorSelectionItem'
import { Typography, TYPOGRAPHY_WEIGHT } from 'components/Typography'
import { useRFPSurveyContext } from 'features/RFP/RFPDetails/Providers/RFPSurveyContextProvider'
import { useRFPDetailsContext } from 'features/RFP/RFPDetails/Providers/RFPDetailsContextProvider'
import { IRFPVendor_old } from 'features/RFP/RFPSurvey/types'
import { TListItem } from 'components/Forms/forms.user.d'
import { notification } from 'components/Notification'
import { VALIDATION_MESSAGES } from 'constants/txt'

const RFPVendorSelection: FC = () => {
  const [selectedVendors, setSelectedVendors] = useState<
    Array<IRFPVendor_old['uuid']>
  >([])
  const [vendorsCategories, setVendorsCategories] = useState<{
    [key in IRFPVendor_old['uuid']]: Array<TListItem['uuid']>
  }>({})

  const rfpDetailsContext = useRFPDetailsContext()
  const rfpSurveyContext = useRFPSurveyContext()

  const { selectedVendors: vendors, contractCategories } =
    rfpSurveyContext.state

  const {} = rfpDetailsContext.actions
  const { awardVendorsAsync } = rfpDetailsContext.actions

  const getIsVendorChecked = (vendorUuid: string) => {
    return Boolean(selectedVendors.find((uuid) => uuid === vendorUuid))
  }

  const handleSelectVendor = (vendorUuid: string) => {
    if (getIsVendorChecked(vendorUuid)) {
      setSelectedVendors((prevState) =>
        prevState.filter((uuid) => uuid !== vendorUuid)
      )
      return
    }

    setSelectedVendors((prevState) => [...prevState, vendorUuid])
  }

  const handleSelectCategory =
    (vendorUuid: string) => (categoryValues: string[]) => {
      setVendorsCategories((prevState) => ({
        ...prevState,
        [vendorUuid]: categoryValues
      }))
    }

  const hasValidationError = useCallback(() => {
    const selectedCategoriesSet = new Set(
      selectedVendors.map((uuid) => vendorsCategories[uuid]).flat()
    )
    const availableCategories = Array.from(
      new Set(
        Object.keys(contractCategories)
          .map((vendor) => contractCategories[vendor].map((item) => item.id))
          .flat()
      )
    )

    return (
      selectedVendors.length <= 0 ||
      availableCategories.some((c) => !selectedCategoriesSet.has(c))
    )
  }, [contractCategories, selectedVendors, vendorsCategories])

  const handleFormSubmit = () => {
    if (hasValidationError()) {
      notification.error({ message: VALIDATION_MESSAGES.SM0042 })
      return
    }

    const submitData = selectedVendors
      .map((vendorUuid) => ({
        vendor: vendorUuid,
        category: vendorsCategories[vendorUuid]
      }))
      .filter((i) => i.category.length > 0)

    awardVendorsAsync(submitData)
  }

  return (
    <Form
      id="rfp-vendor_selection-form-id"
      className="rfp-vendor_selection-form"
      onFinish={handleFormSubmit}
    >
      <Typography.Headline6 weight={TYPOGRAPHY_WEIGHT.NORMAL}>
        Selected awarded vendors
      </Typography.Headline6>
      {vendors.map((vendor) => {
        return (
          <RFPVendorSelectionItem
            key={vendor.id}
            vendor={vendor}
            availableCategories={contractCategories[vendor.id]}
            selectedCategories={vendorsCategories[vendor.id]}
            checked={getIsVendorChecked(vendor.id)}
            selectVendor={handleSelectVendor}
            selectCategory={handleSelectCategory(vendor.id)}
          />
        )
      })}
    </Form>
  )
}

export default RFPVendorSelection
