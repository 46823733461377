import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo
} from 'react'
import { useParams } from 'react-router-dom'
import { FINANCIAL_TABS } from '../constants'
import useTabs, {
  TabsReturnActions,
  TabsReturnState
} from '../../../hooks/useTabs'

type ContextProps = {
  state: TabsReturnState
  actions: {} & TabsReturnActions
}
const FinancialsContext = createContext<ContextProps>({
  state: null!,
  actions: null!
})

const FinancialsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { tab } = useParams()

  const { state: tabsState, actions: tabsActions } = useTabs({
    tabs: FINANCIAL_TABS,
    activeTab: tab || FINANCIAL_TABS[0].key
  })

  const context = useMemo(
    () => ({
      state: {
        ...tabsState
      },
      actions: { ...tabsActions }
    }),
    [tabsState, tabsActions]
  )

  useEffect(() => {
    if (!!tab) tabsActions.setActiveTab(tab)
  }, [tab])

  return (
    <FinancialsContext.Provider value={context}>
      {children}
    </FinancialsContext.Provider>
  )
}

export const useFinancialsContext = () => useContext(FinancialsContext)

export default FinancialsProvider
