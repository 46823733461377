import './styles.scss'

import { defaultDateFormat } from 'helper/dates'
import { BTN_TXT, LABELS } from 'constants/txt'
import { Typography } from 'components/Typography'
import { Container } from 'components/Container/Container'
import { FieldWithLabel } from 'components/FieldWithLabel/FieldWithLabel'
import { useContractDetailsContext } from 'features/ContractDetails/Providers/ContractDetailsContextProvider'
import AdditionalInfoPreview from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsAdditionalInfo/AdditionalInfoPreview'
import ContractDetailsDocuments from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsDocuments/ContractDetailsDocuments'
import ContractExtensionHistory from 'features/ContractDetails/ContractDetailsGeneral/ContractDetailsPreview/ContractExtensionHistory/ContractExtensionHistory'
import { Tooltip } from '../../../../components/Tooltip/Tooltip'
import { ReactComponent as Information } from 'assets/svg/Information.svg'

const extensionHistory = {
  title: 'Extension history',
  width: 675
}

const ContractDetailsPreview = () => {
  const contractDetailsContext = useContractDetailsContext()

  const {
    details,
    canAllContractInfo,
    isOnboarded,
    adminPaymentFrequencyOptions
  } = contractDetailsContext.state

  const hasExtensionHistory = details?.contract_extension_documents?.length > 0

  return (
    <div className="contract-details-preview">
      <div className="contract-details-preview__container">
        <div className="contract-details-preview__grid">
          <div className="contract-details-preview__row">
            <FieldWithLabel
              title={
                <div className="row align-center gap-4">
                  {LABELS.START_DATE}
                  {isOnboarded && (
                    <Tooltip title={<div>Master agreement start date</div>}>
                      <Information />
                    </Tooltip>
                  )}
                </div>
              }
            >
              <Typography.Body1>
                {defaultDateFormat(details.start_date) || '-'}
              </Typography.Body1>
            </FieldWithLabel>
            <Container.WithHistory
              hasVersionHistory={hasExtensionHistory}
              versionHistory={<ContractExtensionHistory />}
              versionHistoryButtonText={BTN_TXT.SHOW_EXTENSION_HISTORY}
              popupProps={extensionHistory}
            >
              <FieldWithLabel title={LABELS.FINISH_DATE}>
                <Typography.Body1>
                  {defaultDateFormat(details.finish_date) || '-'}
                </Typography.Body1>
              </FieldWithLabel>
            </Container.WithHistory>
          </div>
          {isOnboarded && (
            <div className="contract-details-preview__row">
              <FieldWithLabel title={LABELS.PRICING_EFFECTIVE_DATE}>
                <Typography.Body1>
                  {defaultDateFormat(details.pricing_effective_date)}
                </Typography.Body1>
              </FieldWithLabel>
            </div>
          )}
          {canAllContractInfo && (
            <div className="contract-details-preview__row">
              <FieldWithLabel title={LABELS.ADMIN_FEE}>
                <Typography.Body1>{details.admin_fee}</Typography.Body1>
              </FieldWithLabel>
              <FieldWithLabel title={LABELS.ADMIN_PAYMENT_FREQUENCY}>
                <Typography.Body1>
                  {adminPaymentFrequencyOptions.find(
                    (item) => item.value === details.admin_payment_frequency
                  )?.label || '-'}
                </Typography.Body1>
              </FieldWithLabel>
            </div>
          )}
          {canAllContractInfo && (
            <div className="contract-details-preview__row">
              <FieldWithLabel title={LABELS.PAYMENT_TERM_DAYS}>
                <Typography.Body1>
                  {details.payment_term_days || '-'}
                </Typography.Body1>
              </FieldWithLabel>
            </div>
          )}
        </div>
        <AdditionalInfoPreview />
      </div>
      <ContractDetailsDocuments />
    </div>
  )
}

export default ContractDetailsPreview
