import { FC } from 'react'

import './VendorSelectionItem.scss'
import { Checkbox } from 'components/Checkbox'
import { Select } from 'components/Select'
import { PLACEHOLDERS } from 'constants/txt'
import { IRFPVendorCCPayload } from 'features/RFP/RFPSurvey/types'

const convertCategoryToOption = (categories: IRFPVendorCCPayload[]) =>
  categories.map((c) => ({
    label: c.value,
    value: c.id
  }))

type Props = {
  vendor: IRFPVendorCCPayload
  checked: boolean
  selectedCategories: string[]
  availableCategories: IRFPVendorCCPayload[]
  selectVendor: (vendorUuid: string) => void
  selectCategory: (categoryValues: string[]) => void
}

const RFPVendorSelectionItem: FC<Props> = ({
  vendor,
  checked,
  selectedCategories,
  selectVendor,
  selectCategory,
  availableCategories
}) => {
  const handleCheckboxChange = () => {
    selectVendor(vendor.id)
  }

  const handleSelectorChange = (value) => {
    selectCategory(value)
  }

  return (
    <div className="vendor-selection-item">
      <Checkbox
        propsCheckbox={{
          checked,
          onChange: handleCheckboxChange
        }}
      >
        {vendor.value}
      </Checkbox>
      <Select.Multi
        options={convertCategoryToOption(availableCategories)}
        propsSelect={{
          disabled: !checked,
          value: selectedCategories,
          placeholder: PLACEHOLDERS.SELECT_CATEGORY,
          onChange: handleSelectorChange
        }}
      />
    </div>
  )
}

export default RFPVendorSelectionItem
