import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'antd'
import { Popup } from '../../../components/Popup'
import { Select } from '../../../components/Select'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { getCCForAssign, sendCCForAssign } from './api'
import { formatOptionNameValue } from '../../../helper/optionsFormatters'
import { setLoading } from '../../../redux/store/common/slice'
import { BTN_TXT, LABELS, PLACEHOLDERS } from '../../../constants'
import { TOption } from '../../../components/Select/types'
import { AddCCForm, AddCCPopupProps } from './types'

export const AddCCPopup = ({
  isOpen,
  setIsOpen,
  onUpdateCCList,
  userId
}: AddCCPopupProps) => {
  const [contractCategories, setContractCategories] = useState<TOption[]>([])
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const watchSelectedCC = Form.useWatch('contract_category', form)

  useEffect(() => {
    if (isOpen) {
      onGetCCList()
    }
  }, [isOpen])

  const onGetCCList = async () => {
    try {
      dispatch(setLoading(true))
      const { data } = await getCCForAssign(userId)
      setContractCategories(
        !!data.results.length ? data.results.map(formatOptionNameValue) : []
      )
    } finally {
      dispatch(setLoading(false))
    }
  }

  const onCancel = () => {
    form.resetFields()
    setIsOpen(false)
  }

  const handleFinishForm = async ({ contract_category }: AddCCForm) => {
    try {
      dispatch(setLoading(true))
      await sendCCForAssign(userId, contract_category)
      await onUpdateCCList()
      onCancel()
    } catch (e) {
      dispatch(setLoading(false))
    }
  }

  return (
    <Popup
      visible={isOpen}
      onCancel={onCancel}
      width={447}
      title="Assign contract category"
      className="add-contract-category-popup"
      footer={
        <>
          <Button upperCase onClick={onCancel}>
            {BTN_TXT.CANCEL}
          </Button>
          <Button
            type={BUTTON_TYPES.PRIMARY}
            disabled={!watchSelectedCC}
            form="add-cc-form-id"
            htmlType="submit"
            upperCase
          >
            {BTN_TXT.ADD}
          </Button>
        </>
      }
    >
      <Form<AddCCForm>
        form={form}
        onFinish={handleFinishForm}
        id="add-cc-form-id"
      >
        <Select
          options={contractCategories}
          propsItem={{
            label: LABELS.CONTRACT_CATEGORY,
            name: 'contract_category',
            required: true
          }}
          propsSelect={{
            getPopupContainer: (node) => document?.body ?? node,
            placeholder: PLACEHOLDERS.PLEASE_SELECT
          }}
        />
      </Form>
    </Popup>
  )
}
