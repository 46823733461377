import { IUser } from 'redux/store/user/types'
import { LedByUnion } from '../types'

export const isOwnerCondition = (user: IUser) => ({
  'owner.uuid': user.uuid
})

export const isNotInLedBy = (inLedBY: LedByUnion[]) => ({
  led_by: {
    $nin: inLedBY
  }
})
