import Fetcher from '../../services/Fetcher'
import {
  API_DICTIONARY,
  HTTP_METHODS,
  ResponseWithPagination,
  TOptionsWithNameResponse,
  TOptionsWithValueLabelResponse
} from '../../constants'
import { TSetupEditContractCategoryFormType } from './General/EditContractCategory/types'
import {
  TQueryContractCategoriesParams,
  TQueryLedByParams
} from '../ContractPipeline/types'
import {
  TCCFacilityUsersRequest,
  TContractCategoryDetails,
  TUpdateResponsibleUsersRequestData
} from './types'
import { TResponsibleUser } from '../RFP/RFPDetails/types'
import {
  IContractHealthSystem,
  TGetHealthSystemsRequest
} from '../ContractDetails/HSList/types'
import {
  THealthSystemRequest,
  THealthSystemResponse
} from '../HealthSystems/types'

const fetcher = new Fetcher({})

export const getCCDetailsRequestAsync = async (id: string) =>
  fetcher.request<unknown, TContractCategoryDetails>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${id}/`,
    method: HTTP_METHODS.GET
  })

export const updateCCResponsibleUsersRequestAsync = async (
  id: string,
  data: TUpdateResponsibleUsersRequestData,
  successMessage
) =>
  fetcher.request<
    TUpdateResponsibleUsersRequestData,
    TUpdateResponsibleUsersRequestData
  >({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${id}/${API_DICTIONARY.RESPONSIBLES}`,
    method: HTTP_METHODS.POST,
    successMessage,
    data
  })

export const getAvailableResponsibleUsersRequestAsync = async (id: string) =>
  fetcher.request<unknown, ResponseWithPagination<TResponsibleUser>>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${id}/${API_DICTIONARY.OPTIONS}${API_DICTIONARY.RESPONSIBLES}`,
    method: HTTP_METHODS.GET
  })

export const editCCDetailsRequestAsync = async (
  id: string,
  data: TSetupEditContractCategoryFormType
) =>
  fetcher.request<unknown, TSetupEditContractCategoryFormType>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${id}/`,
    method: HTTP_METHODS.PUT,
    data
  })

export const setupCCDetailsRequestAsync = async (
  data: TSetupEditContractCategoryFormType
) =>
  fetcher.request<unknown, TSetupEditContractCategoryFormType>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${API_DICTIONARY.SETUP}`,
    method: HTTP_METHODS.POST,
    data
  })

export const getContractCategoriesOptions = (
  params: TQueryContractCategoriesParams
) =>
  fetcher.request<unknown, ResponseWithPagination<TOptionsWithNameResponse>>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${API_DICTIONARY.SETUP}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.CATEGORY}`,
    method: HTTP_METHODS.GET,
    params
  })

export const getLedByOptions = (params: TQueryLedByParams) =>
  fetcher.request<
    unknown,
    ResponseWithPagination<TOptionsWithValueLabelResponse>
  >({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${API_DICTIONARY.SETUP}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.LED_BY}`,
    method: HTTP_METHODS.GET,
    params
  })

export const getCCHealthSystems: TGetHealthSystemsRequest = async (id, data) =>
  fetcher.request<unknown, ResponseWithPagination<IContractHealthSystem>>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${id}/${API_DICTIONARY.HEALTH_SYSTEMS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getCCAvailableHealthSystems: TGetHealthSystemsRequest = async (
  id,
  data
) =>
  fetcher.request<unknown, ResponseWithPagination<IContractHealthSystem>>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${id}/${API_DICTIONARY.AVAILABLE_HEALTH_SYSTEMS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getFacilityUsers: TCCFacilityUsersRequest = async (id, data) =>
  fetcher.request({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${id}/${API_DICTIONARY.FACILITY_USERS}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const getAvailableFacilityUsers: TCCFacilityUsersRequest = async (
  id,
  data
) =>
  fetcher.request({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${id}/${API_DICTIONARY.FACILITY_USERS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.USER}`,
    method: HTTP_METHODS.GET,
    ...data
  })

export const updateFacilityUsers: any = async (id, data, successMessage) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${id}/${API_DICTIONARY.FACILITY_USERS}`,
    method: HTTP_METHODS.POST,
    successMessage,
    data
  })
export const deleteFacilityUsers: any = async (id, data, successMessage) =>
  fetcher.request<unknown, unknown>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${id}/${API_DICTIONARY.FACILITY_USERS}${API_DICTIONARY.BULK_REMOVE}`,
    method: HTTP_METHODS.POST,
    successMessage,
    data
  })

export const fetchHealthSystemsList: THealthSystemRequest = async (
  ccId,
  params
) =>
  fetcher.request<unknown, THealthSystemResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${ccId}/${API_DICTIONARY.FACILITY_USERS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.HEALTH_SYSTEM}`,
    method: HTTP_METHODS.GET,
    params
  })
export const fetchHospitalsList: THealthSystemRequest = async (ccId, params) =>
  fetcher.request<unknown, THealthSystemResponse>({
    url: `${API_DICTIONARY.CONTRACTS}${API_DICTIONARY.CATEGORIES}${ccId}/${API_DICTIONARY.FACILITY_USERS}${API_DICTIONARY.OPTIONS}${API_DICTIONARY.HOSPITAL}`,
    method: HTTP_METHODS.GET,
    params
  })
