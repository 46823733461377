import { Popup } from '../../../components/Popup'
import { useCalendarPopup } from '../../../pages/Calendar/Provider/CalendarPopupProvider'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { BTN_TXT, LABELS, PLACEHOLDERS } from '../../../constants'
import { Form } from 'antd'
import useAddEventModal from '../Hooks/useAddEventModal'
import { formatName, validateMessages } from '../../../helper/common'
import cn from 'classnames'
import { Input } from '../../../components/Input'
import { TAddEventFormType } from './types'
import { AddGuestsSelect } from './AddGuestsSelect'
import { LocationSelect } from './LocationSelect'
import TypeOfEventSelect from './TypeOfEventSelect/TypeOfEventSelect'
import { Datepicker } from '../../../components/Datepicker'
import { timelineDateFormat } from '../../../components/Timelines/constants'
import { initialStateAddEventForm } from './constants'
import { Select } from '../../../components/Select'
import {
  filterTimesOptions,
  generateTimeOptions
} from '../../../components/Timelines/utils'
import { KeyboardEvent, useMemo } from 'react'
import { Moment } from 'moment/moment'
import { useCalendarEventContext } from '../../../pages/Calendar/CalendarEventsProvider'
import { getInitialValues } from './helper'
import NotifyUsersModal from './NotifyUsersModal'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { getUser } from '../../../redux/store/user/getters'

const timeOptions = generateTimeOptions()
const AddEventModal = () => {
  const { addEventPopup } = useCalendarPopup()
  const { calendarEventDetails } = useCalendarEventContext().state
  const { state, actions } = addEventPopup
  const user = useSelector(getUser)

  const onClose = () => {
    actions.close()
    form.resetFields()
  }

  const {
    form,
    onSubmit,
    errors,
    disabledDateSameOrBeforeStartDate,
    typesSelectOptions,
    setTypesSelectOptions,
    editEvent,
    setEditFormData,
    isSaveButtonDisabled
  } = useAddEventModal()

  const onSelectInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!/^(?:\d|Backspace)$/.test(e.key)) {
      e.preventDefault()
    }
  }

  const validateEndDate = (_, value: Moment) => {
    const startDate = form.getFieldValue('start_date')
    if (
      !value ||
      !startDate ||
      value.isAfter(startDate) ||
      value.isSame(startDate)
    ) {
      return Promise.resolve()
    }
    return Promise.reject('End date must be after start date')
  }

  const validateEndTime = (_, value: string) => {
    const startTime = form.getFieldValue('start_time')
    const startDate = form.getFieldValue('start_date')
    const endDate = form.getFieldValue('end_date')

    if (!value || !startTime || !startDate || !endDate) {
      return Promise.resolve()
    }

    if (
      endDate.isAfter(startDate) ||
      (endDate.isSame(startDate) &&
        moment(value, 'hh:mm A').isSameOrAfter(moment(startTime, 'hh:mm A')))
    ) {
      return Promise.resolve()
    }

    return Promise.reject('End time must be after start time')
  }

  const handleStartDate = (startDate: Moment | null) => {
    if (!startDate) return
    compareStartEndDate(startDate, form.getFieldValue('end_date'))
  }
  const handleEndDate = (endDate: Moment | null) => {
    if (!endDate) return
    compareStartEndDate(form.getFieldValue('start_date'), endDate)
  }
  const handleStartTime = (startTime: string) => {
    const startDate = form.getFieldValue('start_date')
    const endDate = form.getFieldValue('end_date')
    if (startDate && endDate) {
      compareStartEndTime(
        startTime,
        form.getFieldValue('end_time'),
        startDate,
        endDate
      )
    }
  }

  const compareStartEndDate = (startDate: Moment, endDate: Moment) => {
    if (startDate.isAfter(endDate)) {
      form.setFieldsValue({ end_date: startDate })
      compareStartEndTime(
        form.getFieldValue('start_time'),
        form.getFieldValue('end_time'),
        startDate,
        startDate
      )
    } else {
      compareStartEndTime(
        form.getFieldValue('start_time'),
        form.getFieldValue('end_time'),
        startDate,
        endDate
      )
    }
  }

  const compareStartEndTime = (
    startTime: string,
    endTime: string,
    startDate: Moment,
    endDate: Moment
  ) => {
    if (
      endDate.isSame(startDate, 'day') &&
      moment(startTime, 'hh:mm A').isSameOrAfter(moment(endTime, 'hh:mm A'))
    ) {
      form.setFieldsValue({ end_time: startTime })
    }
  }

  const selectedParticipants = Form.useWatch('participants', form) ?? []
  const selectedStartDate = Form.useWatch('start_date', form)
  const selectedEndDate = Form.useWatch('end_date', form)
  const selectedStartTime = Form.useWatch('start_time', form)
  const startEndEqual =
    !!selectedStartDate &&
    !!selectedEndDate &&
    selectedStartDate.isSame(selectedEndDate, 'day')

  const initialState = useMemo(() => {
    return initialStateAddEventForm(user.timezone.offset)
  }, [user.timezone.offset])

  return (
    <>
      <Popup
        visible={state.visible}
        onCancel={onClose}
        title={`${calendarEventDetails?.uuid ? 'Edit' : 'Add'}` + state.title}
        width={910}
        zIndex={10000}
        footer={
          <>
            <Button type={BUTTON_TYPES.DEFAULT} upperCase onClick={onClose}>
              {BTN_TXT.CANCEL}
            </Button>
            <Button
              type={BUTTON_TYPES.PRIMARY}
              upperCase
              form="add-edit-event-calendar-form"
              htmlType="submit"
              disabled={isSaveButtonDisabled}
            >
              {BTN_TXT.SAVE}
            </Button>
          </>
        }
      >
        <Form<TAddEventFormType>
          form={form}
          onFinish={onSubmit}
          initialValues={
            getInitialValues(calendarEventDetails, user.timezone.offset) ||
            initialState
          }
          validateMessages={validateMessages}
          id="add-edit-event-calendar-form"
          className={cn('add-edit-event-calendar-form')}
          onValuesChange={(_, val) =>
            setEditFormData((prev) => ({
              ...initialState,
              ...prev,
              ...val
            }))
          }
        >
          <div className="column gap-16">
            <div className="row space-between align-center">
              <Input
                propsItem={{
                  name: ['name'],
                  label: LABELS.NAME,
                  help: errors.name && errors.name[0],
                  validateStatus: errors.name && 'error',
                  rules: [
                    {
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]+$/,
                      max: 100
                    }
                  ],
                  normalize: formatName
                }}
                propsInput={{
                  showCount: false,
                  maxLength: 100
                }}
                className={'name-input'}
              />
              <TypeOfEventSelect
                onChangeValue={(value, other?: string | null) => {
                  form.setFieldsValue({
                    event_type: !!value
                      ? value === 'other'
                        ? other || ''
                        : value
                      : ''
                  })
                }}
                value={Form.useWatch('event_type', form)}
                typesSelectOptions={typesSelectOptions}
                otherType={
                  calendarEventDetails?.event_type?.id === 'other'
                    ? calendarEventDetails?.event_type?.value
                    : ''
                }
                setTypesSelectOptions={setTypesSelectOptions}
              />
            </div>
            <LocationSelect required={selectedParticipants.length > 0} />
            <div className="row mt-0 gap-16 align-center">
              <Datepicker
                propsItem={{
                  name: 'start_date',
                  label: LABELS.START_DATE,
                  rules: [
                    {
                      required: true
                    }
                  ]
                }}
                propsDate={{
                  format: timelineDateFormat,
                  placeholder: PLACEHOLDERS.MM_DD_YYYY,
                  onChange: handleStartDate
                }}
                className="add-event-datepicker"
              />
              <Select
                className="timeline__time-selector single-with-check"
                options={timeOptions}
                dropdownClassName="single-with-check"
                propsItem={{
                  label: LABELS.START_TIME,
                  name: 'start_time',
                  rules: [{ required: true }],
                  help: errors.start_time && errors.start_time[0],
                  validateStatus: errors.start_time && 'error'
                }}
                propsSelect={{
                  placeholder: PLACEHOLDERS.HH_MM,
                  onInputKeyDown: onSelectInputKeyDown,
                  disabled: !selectedStartDate,
                  virtual: false,
                  onChange: handleStartTime
                }}
              />
            </div>
            <div className="row mt-0 gap-16 align-center">
              <Datepicker
                propsItem={{
                  name: 'end_date',
                  label: LABELS.END_DATE,
                  rules: [
                    {
                      required: true,
                      message: '',
                      validator: validateEndDate
                    }
                  ]
                }}
                propsDate={{
                  disabledDate: disabledDateSameOrBeforeStartDate,
                  format: timelineDateFormat,
                  placeholder: PLACEHOLDERS.MM_DD_YYYY,
                  onChange: handleEndDate
                }}
                className="add-event-datepicker"
              />
              <Select
                className="timeline__time-selector single-with-check"
                options={
                  startEndEqual
                    ? filterTimesOptions(timeOptions, selectedStartTime || '')
                    : timeOptions
                }
                dropdownClassName="single-with-check"
                propsItem={{
                  label: LABELS.END_TIME,
                  name: 'end_time',
                  rules: [{ required: true, validator: validateEndTime }],
                  help: errors.end_time && errors.end_time[0],
                  validateStatus: errors.end_time && 'error'
                }}
                propsSelect={{
                  placeholder: PLACEHOLDERS.HH_MM,
                  onInputKeyDown: onSelectInputKeyDown,
                  disabled: !Form.useWatch('end_date', form),
                  virtual: false
                }}
              />
            </div>
            <AddGuestsSelect
              participants={
                calendarEventDetails?.uuid
                  ? calendarEventDetails?.participants
                  : []
              }
              isEdit={!!calendarEventDetails?.uuid}
              removeParticipant={(uuid) => {
                const values = form.getFieldValue('participants')
                form.setFieldsValue({
                  participants: values.filter((v) => v.value !== uuid)
                })
              }}
            />
            <Input.TextArea
              propsItem={{
                name: 'summary',
                label: LABELS.DESCRIPTION,
                help: errors.summary && errors.summary[0],
                validateStatus: errors.summary && 'error',
                rules: [
                  {
                    max: 500
                  }
                ]
              }}
              propsTextArea={{
                showCount: true,
                maxLength: 500
              }}
            />
          </div>
        </Form>
      </Popup>
      <NotifyUsersModal editEvent={editEvent} />
    </>
  )
}

export default AddEventModal
